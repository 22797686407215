import {
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PackageCheckoutPopup from "../../components/packages/PackageCheckoutPopup";
import {
  SET_TOAST,
  getAllProductPackages,
  getAllProductTypeAction,
  getGrades,
  SET_SUCCESS_ERROR_POPUP,
} from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PackageCheckoutPayment from "../../components/packages/PackageCheckoutPayment";
import BoughtPackages from "../../components/packages/BoughtPackages";
import { charValidate, formatCurrency } from "../../utils/functions";
import { CustomInputLabel } from "../../components/Common/Inputs/InputLabel";
import "react-quill/dist/quill.snow.css";
import { useLocation, useNavigate } from "react-router-dom";
import { CALENDAR_PAGE } from "../../routes/constants";

export default function Packages() {
  const theme = useTheme();
  const ITEM_HEIGHT = 35;
  const ITEM_PADDING_TOP = 5;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 300,
      },
    },
  };
  const selectionInputStyle = { width: "100%", height: 40, width: 300 };
  const cartCountStyle = {
    background: "red",
    padding: "0px 7px",
    fontWeight: 700,
    borderRadius: "100%",
    textAlign: "center",
    color: "white",
    fontSize: 11,
    position: "absolute",
    top: "-10px",
    right: "-10px",
    cursor: "pointer",
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector((state) => state);
  const [dataList, setDataList] = useState([]);
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [selectedPackIds, setSelectedPackIds] = useState([]);
  const [showBoughtPackagesPopup, setShowBoughtPackagesPopup] = useState(false);
  const [showCheckOutPopup, setShowCheckOutPopup] = useState(false);
  const [loader, setLoader] = useState(false);
  const [discountDetails, setDiscountDetails] = useState();
  const [productTypes, setProductTypes] = useState([]);
  const [allGrades, setAllGrades] = useState([]);
  const [selectedProductType, setSelectedProductType] = useState("all");
  const [selectedGrade, setSelectedGrade] = useState("all");

  const orgHeader = state?.org?.org?.data?.product_header_title ?? "";
  const location = useLocation();
  const product = location?.state?.product;

  useEffect(() => {
    getDataList();
    getAllProductTypes();
    getAllGrades();
  }, []);

  useEffect(() => {
    if (selectedProductType || selectedGrade) {
      getDataList();
    }
  }, [selectedProductType, selectedGrade]);

  const getDataList = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
      productType: selectedProductType === "all" ? "" : selectedProductType,
      grade: selectedGrade === "all" ? "" : selectedGrade,
    };
    setLoader(true);
    let response = await getAllProductPackages(params, dispatch);
    if (response?.ok) {
      let dataObj = response?.data ?? [];
      let dataList = [];
      const productIdsData = [];
      for (const key in dataObj) {
        if (dataObj.hasOwnProperty(key)) {
          const data = dataObj[key];
          // if (productIdArr?.length > 0) {
          //   const filteredData = data.filter(item => productIdArr.includes(item.id));
          //   if (filteredData?.length > 0) {
          //     filteredData.map((item) => {
          //       productIdsData.push({
          //         packageId: item?.id,
          //         pack: {
          //           ...item?.packs[0],
          //           total_amount_after_discount: item?.packs[0]?.amount
          //         },
          //         item
          //       })
          //     })
          //   }
          // }
          dataList.push({
            categoryName: key,
            data: data,
          });
        }
      }
      //setSelectedPackages(productIdsData);
      setDataList(dataList ?? []);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  const getAllProductTypes = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let response = await getAllProductTypeAction(params, dispatch);
    if (response?.ok) {
      let dataList = response?.data;
      setProductTypes(dataList);
    } else {
      dispatch({
        type: SET_TOAST,
        data: {
          type: "error",
          message: response?.message ?? "Something went wrong",
        },
      });
    }
  };

  const getAllGrades = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let response = await getGrades(params, dispatch);
    if (response?.ok) {
      let dataList = response?.data;
      setAllGrades(dataList);
    } else {
      dispatch({
        type: SET_TOAST,
        data: {
          type: "error",
          message: response?.message ?? "Something went wrong",
        },
      });
    }
  };

  const handleChangeSessionAmountSelection = (pack, selectedPackage) => {
    // let pack = e?.target?.value ?? "";
    let copy = [...selectedPackages];
    let uniqueArray = [...new Set([...selectedPackIds, pack?.id])];
    setSelectedPackIds(uniqueArray ?? []);
    if (selectedPackages?.length) {
      let arr = copy?.filter((val) => val?.packageId !== selectedPackage?.id);
      setSelectedPackages([
        ...arr,
        {
          packageId: selectedPackage?.id,
          pack: {
            ...pack,
            total_amount_after_discount: pack?.amount,
          },
          selectedPackage,
        },
      ]);
      setShowCheckOutPopup(true);
      // dispatch({
      //   type: SET_SUCCESS_ERROR_POPUP,
      //   data: {
      //     open: true,
      //     type: "success",
      //     message: "Pack added to the cart",
      //   },
      // });
    } else {
      setSelectedPackages([
        {
          packageId: selectedPackage?.id,
          pack: {
            ...pack,
            total_amount_after_discount: pack?.amount,
          },
          selectedPackage,
        },
      ]);
      setShowCheckOutPopup(true);
      // dispatch({
      //   type: SET_SUCCESS_ERROR_POPUP,
      //   data: {
      //     open: true,
      //     type: "success",
      //     message: "Pack added to the cart",
      //   },
      // });
    }
  };

  const handleClosePaymentModal = (isClosed) => {
    setShowCheckOutPopup(false);
    if (!isClosed) {
      setSelectedPackages([]);
      setSelectedPackIds([]);
      setDataList([]);
      getDataList();
      if (product) {
        navigate(`${CALENDAR_PAGE}?calendar_view=SPRINT`, { replace: true });
      }
    }
  };

  const deleteCartItem = (pack, applyCouponCode) => {
    let packs = [];
    let arr = selectedPackages?.filter(
      (val) => val?.packageId !== pack?.product_id,
    );

    arr?.forEach((val) => {
      packs.push(val?.pack?.id);
    });

    setSelectedPackIds(packs ?? []);

    if (!arr?.length) {
      setDiscountDetails(undefined);
      handleClosePaymentModal(false);
    }

    setSelectedPackages(arr);

    setTimeout(() => {
      // applyCouponCode(arr, discountDetails?.isAppliedSKBucks);
      applyCouponCode(arr, false, false, 0, true);
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "success",
          message: "Product removed successfully from cart",
        },
      });
    }, 1000);
  };

  const applyDiscount = (discountedPackages) => {
    setSelectedPackages(discountedPackages);
  };

  const handleCloseMyPacksModal = (isClosed) => {
    setShowBoughtPackagesPopup(false);
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" pb={4}>
      <Box
        sx={{
          width: "100%",
          position: "relative",
          backgroundColor: theme?.palette?.background?.paper,
          boxShadow: theme?.palette?.boxShadow?.tableContainer,
          borderRadius: 1,
          // border: `1px solid ${theme?.palette?.border?.dataGridBorder}`,
        }}
        mt={3}
        pb={3}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px={3.5}
          py={2.5}
        >
          <Typography
            variant="h4"
            fontWeight={700}
            fontSize={20}
            color={theme?.palette?.text?.containerTextLabel}
            mt={1}
          >
            Products
          </Typography>
          <Box display="flex" justifyContent="flex-end">
            {/* {selectedPackages?.length ? (
              <Box
                onClick={() => setShowCheckOutPopup(true)}
                sx={{ position: "relative" }}
              >
                <ShoppingCartIcon
                  fontSize="large"
                  style={{ cursor: "pointer" }}
                />
                <span style={cartCountStyle}>{selectedPackages?.length}</span>
              </Box>
            ) : null} */}
            <Button
              size="small"
              variant="contained"
              sx={{
                paddingY: 1.2,
                paddingX: 2,
                textAlign: "center",
                background: theme.palette.secondary.main,
                ml: 3,
                width: "155px",
              }}
              onClick={() => setShowBoughtPackagesPopup(true)}
            >
              My Purchases
            </Button>
          </Box>
        </Box>
        <Box
          width={1}
          height={"1px"}
          bgcolor={"gray"}
          sx={{ opacity: 0.25 }}
          mt={1}
        ></Box>
        <Box
          sx={{
            px: 4,
            [theme.breakpoints.down("md")]: {
              px: 3,
            },
          }}
        >
          {orgHeader && orgHeader !== "<p>   </p>" ? (
            <Box display="flex" mt={3} mb={product ? 0 : 8}>
              <p dangerouslySetInnerHTML={{ __html: orgHeader }} />
            </Box>
          ) : null}
          {!product && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ mt: 3, mx: 0 }}
            >
              <Grid container spacing={3} alignItems="center">
                <Grid item md={4} sm={6} xs={12}>
                  <CustomInputLabel>Filter By Product Type</CustomInputLabel>
                  <Select
                    name="type"
                    labelId="type"
                    value={selectedProductType}
                    onChange={(e) => setSelectedProductType(e?.target?.value)}
                    input={<OutlinedInput sx={selectionInputStyle} />}
                    MenuProps={MenuProps}
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                    displayEmpty
                    renderValue={(value) => {
                      if (value) {
                        if (value === "all") {
                          return "All";
                        }
                        let type = productTypes?.find(
                          (type) => type?.value === value,
                        );
                        return type?.name ?? "";
                      } else {
                        return (
                          <Typography
                            variant="p"
                            fontWeight={400}
                            fontSize={14}
                            sx={{ opacity: 0.5 }}
                          >
                            Filter by Type
                          </Typography>
                        );
                      }
                    }}
                    className="MuiSelect-select"
                    sx={{
                      "& .MuiSelect-select": {
                        padding: "10.5px 14px",
                      },
                      width: 1,
                      maxWidth: "100%",
                    }}
                  >
                    <MenuItem value={"all"}>All</MenuItem>
                    {productTypes?.map((type) => (
                      <MenuItem key={type?.value} value={type?.value}>
                        {type?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                {/* <Grid item xs={4}>
                <Select
                  name="position"
                  labelId="position"
                  onChange={(e) => { }}
                  value={""}
                  input={
                    <OutlinedInput
                      sx={selectionInputStyle}
                    />
                  }
                  MenuProps={MenuProps}
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  displayEmpty
                  renderValue={(value) => {
                    if (value) {
                      // let selected = tournamentData?.find((tournament) => tournament?.id === value);
                      // if (selected) {
                      //   return selected?.name
                      // }
                      return "";
                    } else {
                      return (
                        <Typography
                          variant="p"
                          fontWeight={400}
                          fontSize={14}
                          sx={{ opacity: 0.5 }}
                        >
                          Filter by Position
                        </Typography>
                      );
                    }
                  }}
                  className="MuiSelect-select"
                  sx={{
                    "& .MuiSelect-select": {
                      padding: "10.5px 14px"
                    },
                    width: 1,
                    maxWidth: "300px"
                  }}
                >
                </Select>
              </Grid> */}
                <Grid item md={4} sm={6} xs={12}>
                  <CustomInputLabel>Filter By Grade</CustomInputLabel>
                  <Select
                    name="grade"
                    labelId="grade"
                    value={selectedGrade}
                    onChange={(e) => setSelectedGrade(e?.target?.value)}
                    input={<OutlinedInput sx={selectionInputStyle} />}
                    MenuProps={MenuProps}
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                    displayEmpty
                    renderValue={(value) => {
                      if (value) {
                        if (value === "all") {
                          return "All";
                        }
                        let type = allGrades?.find(
                          (grade) => grade?.id === value,
                        );
                        return type?.name ?? "";
                      } else {
                        return (
                          <Typography
                            variant="p"
                            fontWeight={400}
                            fontSize={14}
                            sx={{ opacity: 0.5 }}
                          >
                            Filter by Grade
                          </Typography>
                        );
                      }
                    }}
                    className="MuiSelect-select"
                    sx={{
                      "& .MuiSelect-select": {
                        padding: "10.5px 14px",
                      },
                      width: 1,
                      maxWidth: "100%",
                    }}
                  >
                    <MenuItem value={"all"}>All</MenuItem>
                    {allGrades?.map((grade) => (
                      <MenuItem key={grade?.id} value={grade?.id}>
                        {grade?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </Box>
          )}
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={1}
            mx={0}
          >
            <Box
              width={1}
              height={"1px"}
              bgcolor={"gray"}
              sx={{ opacity: 0.25, my: 2 }}
            ></Box>
          </Box>
        </Box>

        {dataList?.length ? (
          dataList?.map((packageCate) => (
            <>
              <Box
                key={packageCate?.id}
                sx={{
                  px: 4,
                  [theme.breakpoints.down("md")]: {
                    px: 3,
                  },
                  display:
                    product && product?.categories
                      ? product?.categories[0]?.title ===
                        packageCate?.categoryName
                        ? "block"
                        : "none"
                      : "block",
                }}
              >
                <Typography
                  variant="h4"
                  fontWeight={700}
                  fontSize={20}
                  color={theme?.palette?.text?.containerTextLabel}
                  mt={3}
                  mb={2}
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      fontSize: "18px",
                    },
                  }}
                >
                  {packageCate?.categoryName ?? "N/A"}
                </Typography>
                <Grid container spacing={2}>
                  {packageCate?.data?.map((packageInfo) =>
                    packageInfo?.is_activated ? (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={4}
                        xl={4}
                        mb={2}
                        key={packageInfo?.id}
                        flexWrap={"wrap"}
                        sx={{
                          width: "100%",
                          maxWidth: "100%",
                          height: "15rem",
                          // maxWidth: "450px",
                          display:
                            product && product?.categories
                              ? product?.id === packageInfo?.id
                                ? "block"
                                : "none"
                              : "block",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexWrap: "wrap",
                          }}
                        >
                          <Box
                            sx={{
                              borderRadius: "7px",
                              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
                              mx: 0,
                              // my: 2,
                              width: "100%",
                              maxWidth: "100%",
                              height: "15rem",
                              // maxWidth: "450px"
                            }}
                          >
                            <Box
                              sx={{
                                background: theme.palette.secondary.main,
                                height: "1rem",
                                borderTopRightRadius: "7px",
                                borderTopLeftRadius: "7px",
                              }}
                            ></Box>
                            <Box p={3}>
                              <Tooltip title={packageInfo?.name ?? "N/A"}>
                                <Typography
                                  variant="h4"
                                  fontWeight={700}
                                  fontSize={16}
                                  color={
                                    theme?.palette?.text?.containerTextLabel
                                  }
                                >
                                  {packageInfo?.name
                                    ? charValidate(packageInfo?.name, 30)
                                    : "N/A"}
                                </Typography>
                              </Tooltip>
                              <Tooltip title={packageInfo?.desc ?? "N/A"}>
                                <Typography
                                  variant="h4"
                                  fontWeight={500}
                                  fontSize={13}
                                  color={theme?.palette?.grey[500]}
                                  my={1.5}
                                >
                                  {packageInfo?.desc
                                    ? charValidate(packageInfo?.desc, 120)
                                    : "N/A"}
                                </Typography>
                              </Tooltip>
                              <Select
                                name="package"
                                labelId="package"
                                onChange={(e) => {
                                  let pack = packageInfo?.packs?.find(
                                    (pack) => pack?.id === e?.target?.value,
                                  );
                                  handleChangeSessionAmountSelection(
                                    pack,
                                    packageInfo,
                                  );
                                }}
                                input={
                                  <OutlinedInput sx={selectionInputStyle} />
                                }
                                MenuProps={MenuProps}
                                inputProps={{
                                  "aria-label": "Without label",
                                }}
                                displayEmpty
                                value={selectedPackIds}
                                renderValue={(value) => {
                                  let selected;
                                  selected = packageInfo?.packs?.find((pack) =>
                                    value.includes(pack?.id),
                                  );
                                  if (selected) {
                                    return selected?.title;
                                  } else {
                                    return (
                                      <Typography
                                        variant="p"
                                        fontWeight={400}
                                        fontSize={14}
                                        sx={{ opacity: 0.5 }}
                                      >
                                        Choose Session Amount
                                      </Typography>
                                    );
                                  }
                                }}
                                className="MuiSelect-select"
                                sx={{
                                  "& .MuiSelect-select": {
                                    padding: "10.5px 14px",
                                  },
                                  width: 1,
                                  maxWidth: "300px",
                                }}
                              >
                                {packageInfo?.packs?.map((pack) =>
                                  pack?.is_active ? (
                                    <MenuItem key={pack?.id} value={pack?.id}>
                                      {pack?.title
                                        ? charValidate(pack?.title, 20)
                                        : "N/A"}{" "}
                                      ({formatCurrency(pack?.amount)})
                                    </MenuItem>
                                  ) : null,
                                )}
                              </Select>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    ) : null,
                  )}
                </Grid>
              </Box>
              <Divider
                sx={{
                  mt: 4,
                  mb: 4,
                  mx: 4,
                  display:
                    product && product?.categories
                      ? product?.categories[0]?.title ===
                        packageCate?.categoryName
                        ? "block"
                        : "none"
                      : "block",
                }}
              ></Divider>
            </>
          ))
        ) : (
          <Typography
            variant="h4"
            fontWeight={500}
            fontSize={15}
            sx={{
              textAlign: "center",
              position: "absolute",
              left: 0,
              right: 0,
              py: 5,
            }}
          >
            {loader
              ? "Loading..."
              : "There is no product & package has been created yet by admin"}
          </Typography>
        )}
      </Box>
      {/* <PackageCheckoutPopup
        open={showCheckOutPopup}
        handleClose={() => {
          setShowCheckOutPopup(false);
        }}
      /> */}
      {showCheckOutPopup && (
        <PackageCheckoutPayment
          open={showCheckOutPopup}
          handleClose={handleClosePaymentModal}
          selectedPackages={selectedPackages}
          deleteCartItem={deleteCartItem}
          applyDiscount={applyDiscount}
          setDiscountDetails={(values) => {
            setDiscountDetails(values);
          }}
          discountDetails={discountDetails}
          resetSelectedPackIds={() => setSelectedPackIds([])}
        />
      )}
      {showBoughtPackagesPopup && (
        <BoughtPackages
          open={showBoughtPackagesPopup}
          handleClose={handleCloseMyPacksModal}
        />
      )}
    </Box>
  );
}
