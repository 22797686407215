import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Drawer,
  Box,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  useTheme,
  Select,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText,
} from "@mui/material";
import httpClient from "@utils/httpClient";
import {
  SET_LOADER,
  SET_TOAST,
  SET_SUCCESS_ERROR_POPUP,
  getEventDetails,
} from "@store/actions";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "../style.css";
import {
  timeFormat,
  dateFormat,
  formatCurrency,
  charValidate,
} from "../../../utils/functions";
import moment from "moment";
import {
  checkEligibleForEvent,
  checkEligibleForProduct,
  getAllEvents,
  getMe,
  registerEvent,
  registerEventAction,
} from "../../../store/actions";
import { GoogleMap, Marker, LoadScript } from "@react-google-maps/api";
import EventRegisterAthleteSelection from "./EventRegisterAthleteSelection";
import EventRegisterPaymentSelection from "./EventRegisterPaymentSelection";
import EventRegisterProductSelectionPreview from "./EventRegisterProductSelectionPreview";
import EventRegisterCardPaymentCheckout from "./EventRegisterCardPaymentCheckout";
import { useMutation } from "react-query";
import EventRegisterSuccess from "./EventRegisterSuccess";
import EventRegistrationRequest from "./EventRegistrationRequest";
import { CustomInputLabel } from "../../../components/Common/Inputs/InputLabel";
import ViewEventRegisterUsers from "./ViewEventRegisterUsers";
import { OutlineBtnStyled } from "../../../components/Common/styled";
import CancelConfirmDialog from "../../../components/Common/CancelConfirmDialog";
import QRCode from "react-qr-code";

export const EditEvent = ({
  formData,
  onDeleteEvent,
  onClose,
  hideCloseDeleteBtn = false,
  isCalendarView = false,
  handleEditEvent,
  eventViewType,
  setFormData,
  closeSuccessDrawer,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const state = useSelector((state) => state);
  const [selectedSprintEvent, setSelectedSprintEvent] = useState(
    formData?.sprintDateArray ? formData?.sprintDateArray?.[0]?.id ?? "" : "",
  );
  const [isMultiDay, setIsisMultiDay] = useState(false);
  const [cancelSubc, setCancelSubsc] = useState(false);
  const [open, setOpen] = useState(false);
  const eventRegistrationView = {
    EVENT_INFO_PREVIEW: "EVENT_INFO_PREVIEW",
    ATHLETE_INFO_SELECTION: "ATHLETE_INFO_SELECTION",
    PAYMENT_METHOD_SELECTION: "PAYMENT_METHOD_SELECTION",
    PRODUCT_PAYMENT_SELECTION_PREVIEW: "PRODUCT_PAYMENT_SELECTION_PREVIEW",
    CC_PAYMENT_SELECTION_PREVIEW: "CC_PAYMENT_SELECTION_PREVIEW",
    SUCCESS_POPUP: "SUCCESS_POPUP",
    EVENT_REGISTRATION_REQUEST: "EVENT_REGISTRATION_REQUEST",
    VIEW_EVENT_REGISTER_USERS: "VIEW_EVENT_REGISTER_USERS",
    // CHECK_IN_UNREGISTER_USER_LIST: "CHECK_IN_UNREGISTER_USER_LIST",
  };
  const [eventRegistrationStepView, setEventRegistrationStepView] = useState(
    eventRegistrationView.EVENT_INFO_PREVIEW,
  );
  const [selectedAthlete, setSelectedAthlete] = useState("");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");
  const [productsList, setProducts] = useState([]);
  const [checkAthleteEligibleResponse, setCheckAthleteEligibleResponse] =
    useState("");
  const [isRegisterButtonVisible, setRegisterButtonVisibility] = useState(true);
  const [isAthleteAlreadyRegistered, setAthleteAlreadyRegistered] =
    useState(false);
  const [successPopupTitle, setSuccessPopupTitle] = useState(
    "You are now registered",
  );
  const [individualSelectedEvent, setIndividualSelectedEvent] = useState(null);
  const [showCancelSaveConfirmPopup, setShowCancelSaveConfirmPopup] =
    useState(false);
  const [showQRCode, setShowQRCode] = useState(false);
  const [positionsList, setPositions] = useState(formData?.position ?? []);
  const [selectedPosition, setSelectedPosition] = useState([]);

  const { mutateAsync: checkProductEligibilty } = useMutation(
    checkEligibleForProduct,
  );
  const { mutateAsync: registerEvent } = useMutation(registerEventAction);
  const { mutateAsync: checkEventEligibility } = useMutation(
    checkEligibleForEvent,
  );

  const orgDetails = state?.org?.org?.data;
  const userType = state?.user?.user?.user_type;
  const userId = state?.user?.user?.id;
  const eventPermission = state?.user?.user?.event_permission;
  useEffect(() => {
    if (userType === "player") {
      setSelectedAthlete(userId);
    }
  }, [userType]);

  useEffect(() => {
    if (formData?.id) {
      const isHideButton = hideRegisterButton(formData?.end);
      setRegisterButtonVisibility(isHideButton);
    }
  }, []);

  let isSingleDate = false;

  if (formData) {
    if (formData?.eventDates && formData?.eventDates?.length > 1) {
      const startDate = moment(formData?.eventDates[0]).format("YYYY-MM-DD");
      const endDate = moment(formData?.eventDates[1]).format("YYYY-MM-DD");
      if (startDate === endDate) {
        isSingleDate = true;
      }
    }
  }

  const openDeleteEvent = () => setOpen(true);
  const closeDeleteEvent = () => setOpen(false);
  const lat = 0;
  const long = 0;

  const ITEM_HEIGHT = 35;
  const ITEM_PADDING_TOP = 5;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: "200px",
      },
    },
  };
  const selectionInputStyle = { height: 40, width: "200px" };

  // const hideRegisterButton = (endDate) => {
  //   const endDatePlusOneDay = new Date(endDate);
  //   endDatePlusOneDay.setDate(endDatePlusOneDay.getDate() + 1);
  //   const currentDate = new Date();
  //   const isBeforeEndDatePlusOneDay = currentDate <= endDatePlusOneDay;
  //   return isBeforeEndDatePlusOneDay;
  // }

  const hideRegisterButton = (endDate) => {
    const endDatePlusOneDay = moment(endDate).add(1, "day");
    const currentDate = moment();
    const isBeforeEndDatePlusOneDay =
      currentDate.isSameOrBefore(endDatePlusOneDay);
    return isBeforeEndDatePlusOneDay;
  };

  const checkEligibility = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
      eventId: eventViewType === "SPRINT" ? selectedSprintEvent : formData?.id,
      athleteId: userId,
    };

    let response = await checkEventEligibility({ dispatch, params });
    if (response?.ok) {
      setCheckAthleteEligibleResponse(response?.data);
      if (response?.data?.eligible && !response?.data?.alreadyBooked) {
        if (formData?.cost) {
          handleNext(eventRegistrationView.PAYMENT_METHOD_SELECTION);
        } else {
          eventRegistration();
        }
      } else {
        if (userType === "player") {
          if (!response?.data?.alreadyBooked) {
            if (
              !response?.data?.isDenied &&
              !response?.data?.isPending &&
              formData?.approval_grade
            ) {
              handleNext(eventRegistrationView.EVENT_REGISTRATION_REQUEST);
            }
          } else {
            setAthleteAlreadyRegistered(true);
          }
        } else {
          dispatch({
            type: SET_SUCCESS_ERROR_POPUP,
            data: {
              open: true,
              type: "error",
              message: response?.message
                ? response?.message
                : "Not eligible to register for this event",
            },
          });
        }
      }
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: response?.message ?? "Something went wrong, try again",
        },
      });
    }
  };

  const checkEligibleProduct = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
      eventId: eventViewType === "SPRINT" ? selectedSprintEvent : formData?.id,
      athleteId: selectedAthlete,
    };
    try {
      let response = await checkProductEligibilty({ dispatch, params });
      if (response?.ok) {
        // handleNext("PRODUCT_PAYMENT_SELECTION_PREVIEW");
        if (response?.data && response?.data?.length) {
          setProducts(response?.data ?? []);
          // handleNext("PRODUCT_PAYMENT_SELECTION_PREVIEW");
        } else {
          dispatch({
            type: SET_SUCCESS_ERROR_POPUP,
            data: {
              open: true,
              type: "error",
              message: response?.message ?? "Something went wrong, try again",
            },
          });
        }
      } else {
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: {
            open: true,
            type: "warning",
            isEditEventStyle: true,
            message: "",
            customMessage: () => (
              <Box mt={3}>
                <Typography
                  sx={{
                    textAlign: "center",
                    [theme.breakpoints.down("md")]: {
                      fontSize: 15,
                    },
                  }}
                  fontFamily={"Poppins"}
                  fontSize={18}
                  fontWeight={500}
                  color={theme?.palette?.warning?.blue}
                >
                  The event you are registering for requires a product/package
                  that you do not currently own.
                </Typography>
                <Typography
                  sx={{
                    my: 3,
                    textAlign: "center",
                    [theme.breakpoints.down("md")]: {
                      fontSize: 15,
                    },
                  }}
                  fontFamily={"Poppins"}
                  fontSize={18}
                  fontWeight={500}
                  color={theme?.palette?.warning?.blue}
                >
                  In order to continue, you must purchase an eligible package by
                  clicking on one of the packages listed.
                </Typography>
              </Box>
            ),
          },
        });
      }
    } catch (error) {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: error?.message ?? "Something went wrong, try again",
        },
      });
    }
  };

  const handleNext = (view) => {
    setEventRegistrationStepView(view);
  };

  const handleStepView = (view) => {
    setEventRegistrationStepView(view);
  };

  const eventRegistration = async (params) => {
    let tokenParams = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let registerWith = "CARD_PAYMENT";
    registerWith =
      params?.total_amount == 0
        ? "FREE"
        : formData?.cost
        ? params?.transaction_id
          ? "CARD_PAYMENT"
          : "PACK"
        : "FREE";

    let bodyParam = {
      event_id: eventViewType === "SPRINT" ? selectedSprintEvent : formData?.id,
      athlete_id: selectedAthlete,
      transaction_id:
        params?.total_amount == 0
          ? "free"
          : formData?.cost
          ? params?.transaction_id
            ? params?.transaction_id
            : "pack"
          : "free",
      paid_amount:
        params?.total_amount == 0
          ? 0
          : params?.total_amount
          ? params?.total_amount
          : formData?.cost
          ? formData?.cost
          : 0,
      discount: params?.discount ? params?.discount : "",
      discount_value: params?.discount_value ?? 0,
      discount_code_amount: params?.discount_code_amount ?? 0,
      is_apply_scholarship:
        checkAthleteEligibleResponse?.isScholarShipAllowed ?? false,
      scholarship_amount:
        checkAthleteEligibleResponse?.scholarship_discount ?? 0,
      paid_with_pack: selectedProduct ? true : false,
      pack: selectedProduct ? selectedProduct : 0,
      sketchplay_bucks: selectedProduct
        ? undefined
        : params?.sketchplay_bucks ?? 0,
      original_amount: formData?.cost,
      position: selectedPosition ?? [],
    };

    let response = await registerEvent({
      dispatch,
      bodyParam,
      registerWith,
      tokenParams,
    });
    if (response?.ok) {
      // dispatch({
      //   type: SET_TOAST,
      //   data: {
      //     type: "success",
      //     message: response?.message ?? "Success",
      //   },
      // });
      dispatch(getMe());
      setSuccessPopupTitle(response?.message ?? "You are now registered");
      handleNext("SUCCESS_POPUP");
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: response?.message ?? "Something went wrong, try again",
        },
      });
    }
  };

  // const handleFormatCost = (cost) => {
  //   if (typeof cost === "string") {
  //     return cost;
  //   } else if (typeof formData?.cost === "number" && Number.isInteger(cost)) {
  //     return formatCurrency(cost, "", true)
  //   } else {
  //     return formatCurrency(cost, "", false)
  //   }
  // }

  const handleFormatCost = (cost) => {
    if (typeof cost === "string") {
      const matches = cost.match(/^([^0-9]*)([0-9].*)$/);
      if (matches) {
        const cleanedNumeric = matches[2].replace(/^0+/, "");
        return matches[1] + cleanedNumeric;
      }
      return cost;
    } else if (typeof formData?.cost === "number" && Number.isInteger(cost)) {
      return formatCurrency(cost, "", true);
    } else {
      return formatCurrency(cost, "", false);
    }
  };

  const renderRegisterBtn = () => {
    if (userType !== "admin" && isRegisterButtonVisible) {
      if (formData?.available_seats !== 0) {
        if (userType === "player" && formData?.allow_athletes) {
          if (checkAthleteEligibleResponse?.alreadyBooked) {
            return null;
          } else {
            return (
              <Button
                className="btn btn-black"
                onClick={() => {
                  // if (selectedPosition?.length) {
                  checkEligibility();
                  // } else {
                  //   dispatch({
                  //     type: SET_SUCCESS_ERROR_POPUP,
                  //     data: {
                  //       open: true,
                  //       type: "error",
                  //       message: "Please select player position",
                  //     },
                  //   });
                  // }
                }}
                sx={{
                  background: `${theme?.palette?.inputsLabels?.green} !important`,
                  width: "80% !important",
                  padding: "0.5rem !important",
                  borderRadius: "10px !important",
                }}
              >
                Register
              </Button>
            );
          }
        } else {
          if (userType === "parent") {
            return (
              <Button
                className="btn btn-black"
                onClick={() => {
                  // if (selectedPosition?.length) {
                  handleNext(eventRegistrationView.ATHLETE_INFO_SELECTION);
                  // } else {
                  //   dispatch({
                  //     type: SET_SUCCESS_ERROR_POPUP,
                  //     data: {
                  //       open: true,
                  //       type: "error",
                  //       message: "Please select player position",
                  //     },
                  //   });
                  // }
                }}
                sx={{
                  background: `${theme?.palette?.inputsLabels?.green} !important`,
                  width: "100% !important",
                  padding: "0.5rem !important",
                  borderRadius: "10px !important",
                }}
              >
                Register
              </Button>
            );
          } else {
            return null;
          }
        }
      } else {
        return (
          <>
            <Typography
              variant="p"
              sx={{
                color: theme?.palette?.error?.dark,
                fontWeight: 600,
                fontFamily: "Poppins",
                fontStyle: "italic",
                mb: 3,
                mt: 0,
              }}
            >
              No seats are available for this event.
            </Typography>
          </>
        );
      }
    } else {
      return null;
    }
  };

  const inputString = formData?.description;
  const sanitizedDescription = inputString.replace(
    /(<p>.*?)(<br>)(.*?<\/p>)/g,
    (match, pTagContent, brTag, remainingContent) => {
      const firstBrReplacement = remainingContent.replace(/<br>/, "");
      return `${pTagContent}${firstBrReplacement}`;
    },
  );

  const handleChangeDate = async (event) => {
    const eventId = event?.target?.value;
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
      eventId: eventId,
    };
    let response = await getEventDetails(params, dispatch);
    if (response?.ok) {
    setSelectedSprintEvent(eventId);
      const sprintDate = formData?.sprintDateArray;
      const event = response?.data;
      let newFormData = {
        id: event?.id,
        eventDates: [event?.start_date, event?.end_date],
        startTime: event?.start_time,
        endTime: event?.end_time,
        cost: event?.cost,
        locations: [],
        visibilty: event?.visibility ?? "",
        attendees: event?.max_seats === -1 ? "" : event?.max_seats,
        timezone: event?.timezone,
        start: event?.start,
        end: event?.end,
        products: event?.products ?? [],
        title: event?.title,
        description: event?.description,
        color: event?.color,
        approval_grade: event?.approval_grade,
        allow_athletes: event?.allow_athletes,
        allow_discount_code: event?.allow_discount_code,
        allow_drop_in: event?.allow_drop_in,
        allow_scholarship: event?.allow_scholarship,
        already_registered: event?.already_registered,
        dateSelectionOpt: event?.is_range,
        sprintDateArray: event?.sprintDateArray ?? [],
        total_players: event?.total_players,
        position: event?.position ?? [],
        class_type: event?.class_type ?? "",
        recurrence_pattern_date: event?.recurrence_pattern_date ?? [],
        grade: event?.grade ?? [],
        qr_data: event?.qr_data,
      };
      newFormData.sprintDateArray = sprintDate;
      const isHideButton = hideRegisterButton(
        new Date(event?.end_date + " " + event?.end_time),
      );
      setRegisterButtonVisibility(isHideButton);
      setIndividualSelectedEvent({
        ...event,
        start: new Date(event?.start_date + " " + event?.start_time),
        end: new Date(event?.end_date + " " + event?.end_time),
      });
      setFormData(newFormData);
      setPositions(event?.position ?? []);
      return event?.main_event?.id ?? "";
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: response?.message ?? "Something went wrong, try again",
        },
      });
    }
  };

  const editMasterEvent = () => {
    getMasterEventDetails(formData?.main_event_id);
  };

  const getMasterEventDetails = async (eventId) => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
      eventId: eventId,
    };

    let response = await getEventDetails(params, dispatch);
    if (response?.ok) {
      const event = response?.data;
      handleEditEvent("edit", {
        ...event,
        start: new Date(event?.start_date + " " + event?.start_time),
        end: new Date(event?.end_date + " " + event?.end_time),
      });
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: response?.message ?? "Something went wrong, try again",
        },
      });
    }
  };

  const handleChangePositionSelection = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedPosition(typeof value === "string" ? value.split(",") : value);
  };

  if (eventRegistrationStepView === eventRegistrationView.EVENT_INFO_PREVIEW) {
    return (
      <>
        <Box className="event-details-main">
          <Box className="event-map-box">
            {/* <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.521260322283!2d106.8195613507864!3d-6.194741395493371!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f5390917b759%3A0x6b45e67356080477!2sPT%20Kulkul%20Teknologi%20Internasional!5e0!3m2!1sen!2sid!4v1601138221085!5m2!1sen!2sid"
                        width="600"
                        height="450"
                        frameBorder="0"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        aria-hidden="false"
                        tabIndex="0"
                    /> */}
            <LoadScript googleMapsApiKey="AIzaSyCoktcWG6Ty4_6lEwrZaQRoHvQSjQZnHl4">
              <GoogleMap
                center={
                  formData?.locations?.length ? formData?.locations[0] : []
                }
                zoom={10}
                mapContainerStyle={{
                  width: "100%",
                  height: "450px",
                }}
              >
                {formData.locations?.length > 0 &&
                  formData.locations?.map((location) => (
                    <Marker position={location} />
                  ))}
              </GoogleMap>
            </LoadScript>
          </Box>
          <Box className="event-details-box">
            <Box className="event-top-box">
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  margin: "-1.5rem 0 0 -1.5rem",
                }}
              >
                <Box
                  className="event-price"
                  sx={{ background: `${orgDetails?.primary_color} !important` }}
                >
                  {formData?.cost === "$0"
                    ? "$0"
                    : handleFormatCost(formData?.cost)}
                </Box>
                <Box className="event-type-box">
                  {/* <span>{formData?.visibility}</span> */}
                  <span>
                    {formData?.visibilty
                      ? formData?.visibilty?.flag
                        ? "Private"
                        : "Public"
                      : "N/A"}
                  </span>
                  <span
                    className="devider-line"
                    style={{
                      backgroundColor: formData?.color
                        ? formData?.color
                        : "#AC4DFF",
                    }}
                  ></span>
                </Box>
              </Box>
              <h3>{formData?.title ?? "N/A"}</h3>
              <Box
                className="event-time-name-box"
                sx={{
                  maxWidth: "100% !important",
                  width: "100% !important",
                  background: `${orgDetails?.primary_color} !important`,
                }}
              >
                <span
                  style={{
                    background: orgDetails?.secondary_color,
                    fontSize: "14px",
                    cursor:
                      formData?.total_players && formData?.total_players > 0
                        ? "pointer"
                        : "initial",
                  }}
                  onClick={() => {
                    if (
                      formData?.total_players &&
                      formData?.total_players > 0
                    ) {
                      handleNext(
                        eventRegistrationView.VIEW_EVENT_REGISTER_USERS,
                      );
                    }
                  }}
                >
                  {formData?.total_players ? formData?.total_players : "0"}
                </span>

                {/* <span>
                                {formData.available_seats === -1 ? "∞" : formData.available_seats || 0}
                            </span> */}
                {/* <Box className='event-time-name'>
                                <p> {moment(formData.start_date).format('dddd, MMMM Do YYYY')} {isMultiDay && `- ${moment(formData.end_date).format('dddd, MMMM Do YYYY')}`} <br /> {timeFormat(formData.start_time)} - {timeFormat(formData.end_time)}, {formData.timezone}</p>
                            </Box> */}
                <Box display={"flex"} flexDirection={"column"}>
                  <Box
                    className="event-time-name"
                    sx={{
                      fontFamily: "Poppins !important",
                      fontSize: "13px !important",
                      display:
                        formData?.eventDates?.length <= 2 ? "flex" : "block",
                    }}
                  >
                    {isSingleDate ? (
                      moment(formData?.eventDates[0]).format("dddd, MMM DD")
                    ) : // formData?.eventDates?.length === 1 ?
                    //   moment(formData?.eventDates[0]).format('dddd, MMM DD') :
                    // formData?.eventDates?.length <= 2 ?
                    //   moment(formData?.eventDates[0]).format('dddd, MMM DD') -  moment(formData?.eventDates[1]).format('dddd, MMM DD')
                    // :
                    formData?.eventDates && formData?.eventDates?.length ? (
                      formData?.eventDates?.map((date, index) => (
                        <p>
                          {moment(date).format("dddd, MMM DD")}{" "}
                          {formData?.eventDates?.length - 1 !== index
                            ? formData?.dateSelectionOpt
                              ? "-"
                              : ","
                            : ""}
                          &nbsp;
                        </p>
                      ))
                    ) : (
                      <p>N/A</p>
                    )}
                  </Box>
                  <Box
                    className="event-time-name"
                    sx={{
                      fontFamily: "Poppins !important",
                      fontSize: "13px !important",
                    }}
                  >
                    {/* {moment(formData?.start).format('LT')} - {moment(formData?.end).format('LT')} ({formData?.timezone}) */}
                    {formData?.startTime} - {formData?.endTime},{" "}
                    {formData?.timezone}
                  </Box>
                  {formData?.attendees ? (
                    <Box
                      className="event-time-name"
                      sx={{
                        fontFamily: "Poppins !important",
                        fontSize: "13px !important",
                      }}
                    >
                      Max Limit : {formData?.attendees}
                    </Box>
                  ) : null}
                </Box>
              </Box>
            </Box>
            {formData?.event_sprint && (
              <Box
                sx={{
                  mt: 2,
                  mb: 2,
                  textAlign: "center",
                }}
              >
                <Typography
                  variant="div"
                  sx={{
                    fontWeight: 300,
                    fontFamily: "Poppins",
                  }}
                >
                  Sprint : {formData?.event_sprint}
                </Typography>
              </Box>
            )}

            <Box className="event-middle-box">
              {showQRCode ? (
                <>
                  <Box
                    my={3}
                    sx={{ textAlign: "center", position: "relative" }}
                  >
                    {/* <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 80,
                        height: 80,
                        //borderRadius: '50%',
                        backgroundColor: '#000000',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}>
                        <Box sx={{
                          color: '#FFFFFF',
                          fontSize: '55px',
                          fontWeight: 'bold',
                          textTransform: 'uppercase',
                        }}>
                          S
                        </Box>
                      </Box> */}
                    <QRCode
                      title="S"
                      value={`https://stage-gridiron.sketchplay.io/${formData?.qr_data}`}
                      //value={`https://stage-gridiron.sketchplay.io/calendar?calendar_view=SPRINT&eventId=${formData?.id}`}
                      bgColor="#FFFFFF"
                      fgColor="#000000"
                      size={256}
                    />
                  </Box>
                </>
              ) : (
                <>
                  {eventViewType === "SPRINT" && !formData?.qr_eventId ? (
                    userType === "parent" ||
                    userType === "player" ||
                    userType === "admin" ? (
                      <Box my={3} sx={{ textAlign: "center" }}>
                        <CustomInputLabel>
                          {userType === "admin"
                            ? "Choose the recurring date event"
                            : "Choose which date you are registering for:"}
                        </CustomInputLabel>
                        <Select
                          required={true}
                          onChange={(e) => handleChangeDate(e)}
                          value={selectedSprintEvent}
                          input={<OutlinedInput sx={selectionInputStyle} />}
                          MenuProps={MenuProps}
                          inputProps={{
                            "aria-label": "Without label",
                          }}
                          displayEmpty
                          renderValue={(value) => {
                            if (value) {
                              let selected = formData?.sprintDateArray?.find(
                                (sprintDate) => sprintDate?.id === value,
                              );
                              if (selected) {
                                return `${moment(selected?.start_date).format(
                                  "LL",
                                )}, ${selected.start_time}`;
                              }
                            } else {
                              return (
                                <Typography
                                  variant="p"
                                  fontWeight={400}
                                  fontSize={14}
                                  sx={{ opacity: 0.5 }}
                                >
                                  Choose date...
                                </Typography>
                              );
                            }
                          }}
                          className="MuiSelect-select"
                          sx={{
                            "& .MuiSelect-select": {
                              padding: "10.5px 14px",
                              background: theme.palette.background.paper,
                            },
                            width: "50%",
                            mb: 1,
                          }}
                        >
                          {formData?.sprintDateArray?.length &&
                            formData?.sprintDateArray?.map((sprintDate) => (
                              <MenuItem
                                key={sprintDate.id}
                                value={sprintDate.id}
                              >
                                {`${moment(sprintDate?.start_date).format(
                                  "LL",
                                )}, ${sprintDate.start_time}`}
                              </MenuItem>
                            ))}
                        </Select>
                      </Box>
                    ) : null
                  ) : null}

                  {userType === "player" && positionsList?.length ? (
                    <Box my={2} sx={{ textAlign: "center" }}>
                      <CustomInputLabel>POSITION</CustomInputLabel>
                      <Select
                        multiple
                        displayEmpty
                        value={selectedPosition}
                        onChange={handleChangePositionSelection}
                        input={
                          <OutlinedInput
                            label=""
                            placeholder="Select position..."
                            sx={selectionInputStyle}
                          />
                        }
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        sx={{
                          "& .MuiSelect-select": {
                            padding: "10.5px 14px",
                            background: theme.palette.background.paper,
                          },
                          width: "50%",
                          mb: 1,
                        }}
                        renderValue={(selected) => {
                          if (selected?.length && positionsList?.length) {
                            let positions = [];
                            positionsList?.forEach((position) => {
                              if (selected.includes(position?.id)) {
                                positions.push(position?.name);
                              }
                            });
                            if (positions?.length) {
                              return positions.join(", ");
                            }
                            return selected?.join(", ");
                          } else {
                            return (
                              <Typography
                                variant="p"
                                fontWeight={400}
                                fontSize={14}
                                sx={{ opacity: 0.5 }}
                              >
                                Select one or more position...
                              </Typography>
                            );
                          }
                        }}
                        // MenuProps={MenuProps}
                      >
                        {positionsList?.length
                          ? positionsList?.map((position) => (
                              <MenuItem key={position?.id} value={position?.id}>
                                <Checkbox
                                  checked={
                                    selectedPosition.indexOf(position?.id) > -1
                                  }
                                />
                                <ListItemText primary={position?.name} />
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    </Box>
                  ) : null}

                  {formData?.description && (
                    <p
                      dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
                    />
                  )}
                </>
              )}
            </Box>
            {/* {user.user_type === "coach" && */}

            <Box
              className="event-bottom-box"
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <>
                {showQRCode ? (
                  <>
                    <Button
                      variant="outlined"
                      onClick={() => setShowQRCode(false)}
                      sx={{
                        mt: 3,
                        width: "100%",
                        height: "55px",
                        background: `white !important`,
                        border: `2px solid ${orgDetails?.primary_color} !important`,
                        color: `${orgDetails?.primary_color} !important`,
                      }}
                    >
                      Back to Event
                    </Button>
                  </>
                ) : (
                  <>
                    {eventViewType !== "SPRINT" && userType === "admin" ? (
                      <Button
                        className="btn btn-black"
                        onClick={() => handleEditEvent("edit")}
                        sx={{
                          background: `${orgDetails?.primary_color} !important`,
                        }}
                      >
                        Edit Event
                      </Button>
                    ) : null}

                    {eventViewType === "SPRINT" &&
                    userType === "admin" &&
                    (formData?.class_type === "camp" ||
                      formData?.class_type === "clinic") ? (
                      <Button
                        className="btn btn-black"
                        onClick={() => handleEditEvent("edit")}
                        sx={{
                          background: `${orgDetails?.primary_color} !important`,
                        }}
                      >
                        Edit Event
                      </Button>
                    ) : null}

                    {eventViewType === "SPRINT" &&
                    userType === "admin" &&
                    (formData?.class_type === "dailytraining" ||
                      formData?.class_type === "misc") ? (
                      <Button
                        className="btn btn-black"
                        onClick={() => setShowCancelSaveConfirmPopup(true)}
                        sx={{
                          background: `${orgDetails?.primary_color} !important`,
                        }}
                      >
                        Edit Master Event
                      </Button>
                    ) : null}

                    {eventViewType === "SPRINT" &&
                    userType === "admin" &&
                    (formData?.class_type === "dailytraining" ||
                      formData?.class_type === "misc") ? (
                      <Button
                        className="btn btn-black"
                        onClick={() =>
                          handleEditEvent("edit", individualSelectedEvent)
                        }
                        sx={{
                          background: `white !important`,
                          border: `2px solid ${orgDetails?.primary_color} !important`,
                          color: `${orgDetails?.primary_color} !important`,
                        }}
                      >
                        Edit This Child Event
                      </Button>
                    ) : null}

                    {(userType === "admin" && formData?.id) ||
                    eventPermission ? (
                      <Button
                        variant="outlined"
                        onClick={() => setShowQRCode(true)}
                        sx={{
                          mt: 3,
                          mb: 2,
                          width: "100%",
                          height: "55px",
                          background: `white !important`,
                          border: `2px solid ${orgDetails?.primary_color} !important`,
                          color: `${orgDetails?.primary_color} !important`,
                        }}
                      >
                        View QR Code
                      </Button>
                    ) : null}

                    {renderRegisterBtn()}
                    {userType === "parent" || userType === "player" ? (
                      <>
                        {!isRegisterButtonVisible && (
                          <Typography
                            variant="p"
                            sx={{
                              color: theme?.palette?.error?.dark,
                              fontWeight: 600,
                              fontFamily: "Poppins",
                              fontStyle: "italic",
                              mb: 3,
                              mt: 0,
                            }}
                          >
                            Registration for this event already closed
                          </Typography>
                        )}
                      </>
                    ) : null}
                    {userType === "player" && !formData?.allow_athletes ? (
                      <Typography
                        variant="p"
                        sx={{
                          color: theme?.palette?.orange?.dark,
                          fontWeight: 600,
                          fontFamily: "Poppins",
                          fontStyle: "italic",
                          mb: 3,
                          mt: 0,
                        }}
                      >
                        Registration for athletes/players is restricted; only
                        parents can register them for this event.
                      </Typography>
                    ) : null}

                    <Box my={3}>
                      {checkAthleteEligibleResponse && userType === "player" ? (
                        checkAthleteEligibleResponse?.isDenied ||
                        checkAthleteEligibleResponse?.isPending ? (
                          <Typography
                            variant="p"
                            sx={{
                              color: checkAthleteEligibleResponse?.isDenied
                                ? "red"
                                : theme?.palette?.dark?.darkTextSubHeader,
                              fontWeight: 600,
                              textAlign: "center",
                              fontFamily: "Poppins",
                              fontStyle: "italic",
                            }}
                          >
                            {checkAthleteEligibleResponse?.isDenied
                              ? "Registration request has been denied by the administrator"
                              : "Registration reuqest is in review by the administrator"}
                          </Typography>
                        ) : checkAthleteEligibleResponse?.alreadyBooked ? (
                          <Typography
                            variant="p"
                            sx={{
                              color: theme?.palette?.dark?.darkTextSubHeader,
                              fontWeight: 600,
                              textAlign: "center",
                              fontFamily: "Poppins",
                              fontStyle: "italic",
                            }}
                          >
                            You are already been registered for this event
                            (registered by you or your parents)
                          </Typography>
                        ) : null
                      ) : // checkAthleteEligibleResponse?.eligible ? null :
                      //   <Typography
                      //     variant="p"
                      //     sx={{
                      //       color: "red",
                      //       fontWeight: 600,
                      //       textAlign: "center",
                      //       fontFamily: "Poppins",
                      //       fontStyle: "italic"
                      //     }}
                      //   >
                      //     The selected athlete is not eligible for this event
                      //   </Typography>
                      null}
                    </Box>

                    {!hideCloseDeleteBtn ? (
                      <>
                        {userType === "admin" ? (
                          <>
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                              sx={{ width: "100%" }}
                            >
                              <Typography
                                variant="h6"
                                sx={{
                                  mt: 2,
                                  color: "red",
                                  fontSize: "14px",
                                  fontWeight: 600,
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                  textAlign: "center",
                                }}
                                onClick={() => onDeleteEvent(formData?.id)}
                              >
                                Delete Event
                              </Typography>
                              {/* {formData?.event_type === "main_event" && ( */}
                              <Typography
                                variant="h6"
                                sx={{
                                  mt: 2,
                                  color: `${theme?.palette?.inputsLabels?.green} !important`,
                                  fontSize: "14px",
                                  fontWeight: 600,
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                  textAlign: "center",
                                }}
                                onClick={() => handleEditEvent("duplicate")}
                              >
                                Duplicate Event
                              </Typography>
                              {/* )} */}
                            </Box>
                          </>
                        ) : null}
                        <Typography
                          variant="h6"
                          sx={{
                            mt: 2,
                            fontSize: "14px",
                            fontWeight: 600,
                            textDecoration: "underline",
                            cursor: "pointer",
                            textAlign: "center",
                            color: orgDetails?.primary_color,
                          }}
                          onClick={onClose}
                        >
                          Close
                        </Typography>
                      </>
                    ) : null}
                  </>
                )}
              </>
              {/* <Button className='btn btn-red'>Delete Event</Button> */}
            </Box>
            {/* } */}
            {/* {user.user_type === "player" && formData?.cost !== 0 && !formData.already_registered ?
                        <Box className="event-bottom-box">
                            <Button className='btn btn-black' onClick={() => onEventStepChange(13)}>Register</Button>
                        </Box>
                        : ""}
                    {user.user_type === "player" && formData?.cost === 0 && !formData.already_registered ? (
                        <Box className="event-bottom-box">
                            <Button className="btn btn-black" onClick={RegisterFree}>
                                Register
                            </Button>
                        </Box>
                    ) : null}

                    {user.user_type === "player" && formData.already_registered ? <Box>
                        <Button variant='contained' fullWidth disabled sx={{ color: "#000000" }}>You are already Register</Button>
                        <Typography variant='body2' sx={{ mt: 4, cursor: "pointer" }} onClick={() => { setOpen(true); setCancelSubsc(true) }}><u>Cancel Subscription</u></Typography>

                    </Box>
                        : ""} */}
          </Box>
        </Box>
        {/* <Dialog
                open={open}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    {cancelSubc ? "Are you sure you want to cancel your event subscription?" : "Are you sure you want to delete this event ?"}
                </DialogContent>
                <DialogActions>
                    {cancelSubc ? <Button variant='outlined' onClick={closeDeleteEvent}>Cancel</Button> : <Button variant='outlined' onClick={closeDeleteEvent}>Cancel, DO NOT DELETE!</Button>}
                    {cancelSubc ? <Button variant='contained' color="error" onClick={CancelSubscription}>Confirm Cancel</Button> : <Button variant='contained' color="error" onClick={() => {
                        onDeleteEvent(formData.id)
                    }}>Confirm Delete</Button>}
                </DialogActions>
            </Dialog> */}
        <CancelConfirmDialog
          title={"Edit Master Event"}
          message={
            "You are about to edit a master event which will overide any single event updates you've made for these recurring events. Do you want to proceed?"
          }
          open={showCancelSaveConfirmPopup}
          handleClose={() => setShowCancelSaveConfirmPopup(false)}
          handleCancel={() => {
            setShowCancelSaveConfirmPopup(false);
          }}
          handleConfirmClose={editMasterEvent}
          confirmBtnLabel={"Yes, Confirm"}
        />
      </>
    );
  } else if (
    eventRegistrationStepView === eventRegistrationView.ATHLETE_INFO_SELECTION
  ) {
    return (
      <EventRegisterAthleteSelection
        onBack={handleStepView}
        handleNext={handleNext}
        setSelectedAthlete={setSelectedAthlete}
        selectedAthlete={selectedAthlete}
        eventId={formData?.id}
        selectedSprintEventId={selectedSprintEvent}
        cost={formData?.cost}
        eventRegistration={eventRegistration}
        setCheckAthleteEligibleResponse={setCheckAthleteEligibleResponse}
        approvalGrade={formData?.approval_grade}
        positionsList={positionsList}
        selectedPosition={selectedPosition}
        handleChangePositionSelection={handleChangePositionSelection}
        eventGrade={formData?.grade}
      />
    );
  } else if (
    eventRegistrationStepView === eventRegistrationView.PAYMENT_METHOD_SELECTION
  ) {
    return (
      <EventRegisterPaymentSelection
        onBack={handleStepView}
        handleNext={handleNext}
        setSelectedPaymentMethod={setSelectedPaymentMethod}
        selectedPaymentMethod={selectedPaymentMethod}
        setSelectedProduct={setSelectedProduct}
        selectedProduct={selectedProduct}
        productsList={productsList}
        checkEligibleProduct={checkEligibleProduct}
        eventRegistration={eventRegistration}
        eventProducts={formData?.products ?? []}
      />
    );
  } else if (
    eventRegistrationStepView ===
    eventRegistrationView.CC_PAYMENT_SELECTION_PREVIEW
  ) {
    return (
      <EventRegisterCardPaymentCheckout
        onBack={handleStepView}
        handleNext={handleNext}
        setSelectedProduct={setProducts}
        cost={formData?.cost}
        eventRegistration={eventRegistration}
        checkAthleteEligibleResponse={checkAthleteEligibleResponse}
        title={formData?.title}
        eventId={formData?.id}
        selectedSprintEventId={selectedSprintEvent}
        selectedAthlete={selectedAthlete}
        allow_discount_code={formData?.allow_discount_code}
        allow_scholarship={formData?.allow_scholarship}
      />
    );
  } else if (
    eventRegistrationStepView === eventRegistrationView.SUCCESS_POPUP
  ) {
    return (
      <EventRegisterSuccess
        title={successPopupTitle}
        closeSuccessDrawer={closeSuccessDrawer}
      />
    );
  } else if (
    eventRegistrationStepView ===
    eventRegistrationView.EVENT_REGISTRATION_REQUEST
  ) {
    return (
      <EventRegistrationRequest
        eventId={formData?.id}
        selectedSprintEventId={selectedSprintEvent}
        selectedAthlete={selectedAthlete}
        setSuccessPopupTitle={setSuccessPopupTitle}
        handleNext={handleNext}
        onBack={(view) => {
          if (userType === "player") {
            handleStepView(eventRegistrationView.EVENT_INFO_PREVIEW);
          } else {
            handleStepView(view);
          }
        }}
      />
    );
  } else if (
    eventRegistrationStepView ===
    eventRegistrationView.VIEW_EVENT_REGISTER_USERS
  ) {
    return (
      <ViewEventRegisterUsers
        eventId={formData?.id}
        handleNext={handleNext}
        onBack={handleStepView}
      />
    );
  }
};

export default EditEvent;
