import { format, parse } from "date-fns";
import dayjs from "dayjs";

export const dateDiffInDays = (fromDate, toDate) => {
  const utc1 = Date.UTC(
    fromDate.getFullYear(),
    fromDate.getMonth(),
    fromDate.getDate(),
  );
  const utc2 = Date.UTC(
    toDate.getFullYear(),
    toDate.getMonth(),
    toDate.getDate(),
  );

  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
};

export const queryParams = (searchStr = "") => {
  let queryAry = {};
  searchStr
    .split("&")
    .filter((x) => x)
    .forEach((x) => {
      let ary = x.split("=");
      ary = ary.map((y) => decodeURI(y));
      queryAry[ary[0]] = ary[1] || "";
    });
  return queryAry;
};

export const obj2QryStr = (params = {}) => {
  return Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");
};

export const dateFormat = (date) => {
  const dd = new Date(date);

  const _year = dd.getUTCFullYear();
  const _month = dd.getUTCMonth() + 1;
  const _date = dd.getUTCDate();

  return `${_month < 10 ? "0" + _month : _month}/${
    _date < 10 ? "0" + _date : _date
  }/${_year}`;
};

export const dateFormatForAPI = (date) => {
  const dd = dayjs(date).format("MM-DD-YYYY");
  return dd;
};

export const DOBFormat = (date) => {
  if (!date) {
    return "N/A";
  }
  const dd = new Date(date);
  if (isNaN(dd.getTime())) {
    return "N/A";
  }
  const _year = dd.getUTCFullYear();
  const _month = dd.getUTCMonth() + 1;
  const _date = dd.getUTCDate();
  return `${_month < 10 ? "0" + _month : _month}/${
    _date < 10 ? "0" + _date : _date
  }/${_year}`;
};

export const dateFormatEvent = (date) => {
  const dd = new Date(date);

  const _year = dd.getUTCFullYear();
  const _month = dd.getUTCMonth() + 1;
  const _date = dd.getDate();

  return `${_month < 10 ? "0" + _month : _month}/${
    _date < 10 ? "0" + _date : _date
  }/${_year}`;
};

export const contentDateFormat = (date) => {
  const moment = require("moment");

  const momentDate = moment(date);
  const formattedDate = momentDate.format("MMM DD, YYYY");
  return formattedDate;
};

export const phoneFormat = (number) => {
  return `${number}`.replace(/(\d{3})(\d{3})(\d{4})/, "($1)-$2-$3");
};

export const html2Text = (text) => {
  let html = `${text}`;

  // REMOVE \n //
  // html = `${html}`.replace(/\n/ig, '')

  // REMOVE HTML TAGS FOR SECURITY PURPOSE //
  html = `${html}`.replace(/<[^>]*>/g, "");

  return html;
};

export const subStr = (text, limit) => {
  text = html2Text(text);
  const length = text.length;
  text = text.slice(0, limit);
  text = length > limit ? `${text}...` : ``;

  return text;
};

export const getBrowserDetail = () => {
  let nAgt = navigator.userAgent;
  let browserName = navigator.appName;
  let fullVersion = "" + parseFloat(navigator.appVersion);
  let majorVersion = parseInt(navigator.appVersion, 10);
  let nameOffset, verOffset, ix;
  let OSName = "Unknown OS";

  // In Opera, the true version is after "Opera" or after "Version"
  if ((verOffset = nAgt.indexOf("Opera")) !== -1) {
    browserName = "Opera";
    fullVersion = nAgt.substring(verOffset + 6);
    if ((verOffset = nAgt.indexOf("Version")) !== -1)
      fullVersion = nAgt.substring(verOffset + 8);
  }
  // In MSIE, the true version is after "MSIE" in userAgent
  else if ((verOffset = nAgt.indexOf("MSIE")) !== -1) {
    browserName = "Microsoft Internet Explorer";
    fullVersion = nAgt.substring(verOffset + 5);
  }
  // In Chrome, the true version is after "Chrome"
  else if ((verOffset = nAgt.indexOf("Chrome")) !== -1) {
    browserName = "Chrome";
    fullVersion = nAgt.substring(verOffset + 7);
  }
  // In Safari, the true version is after "Safari" or after "Version"
  else if ((verOffset = nAgt.indexOf("Safari")) !== -1) {
    browserName = "Safari";
    fullVersion = nAgt.substring(verOffset + 7);
    if ((verOffset = nAgt.indexOf("Version")) !== -1)
      fullVersion = nAgt.substring(verOffset + 8);
  }
  // In Firefox, the true version is after "Firefox"
  else if ((verOffset = nAgt.indexOf("Firefox")) !== -1) {
    browserName = "Firefox";
    fullVersion = nAgt.substring(verOffset + 8);
  }
  // In most other browsers, "name/version" is at the end of userAgent
  else if (
    (nameOffset = nAgt.lastIndexOf(" ") + 1) <
    (verOffset = nAgt.lastIndexOf("/"))
  ) {
    browserName = nAgt.substring(nameOffset, verOffset);
    fullVersion = nAgt.substring(verOffset + 1);
    if (browserName.toLowerCase() === browserName.toUpperCase()) {
      browserName = navigator.appName;
    }
  }

  // trim the fullVersion string at semicolon/space if present
  if ((ix = fullVersion.indexOf(";")) !== -1)
    fullVersion = fullVersion.substring(0, ix);
  if ((ix = fullVersion.indexOf(" ")) !== -1)
    fullVersion = fullVersion.substring(0, ix);

  majorVersion = parseInt("" + fullVersion, 10);
  if (isNaN(majorVersion)) {
    fullVersion = "" + parseFloat(navigator.appVersion);
    majorVersion = parseInt(navigator.appVersion, 10);
  }

  if (navigator.appVersion.indexOf("Win") !== -1) OSName = "Windows";
  if (navigator.appVersion.indexOf("Mac") !== -1) OSName = "MacOS";
  if (navigator.appVersion.indexOf("X11") !== -1) OSName = "UNIX";
  if (navigator.appVersion.indexOf("Linux") !== -1) OSName = "Linux";

  return {
    operating_system: `${OSName}`,
    appName: `${navigator.appName}`,
    userAgent: `${navigator.userAgent}`,
    deviceMemory: `${navigator.deviceMemory}GB`,
    language: `${navigator.language}`,
    outerHeight: `${window.outerHeight}`,
    outerWidth: `${window.outerWidth}`,
    browserName,
    fullVersion,
    majorVersion,
  };
};

export const addDaysToCurrentDate = (days) => {
  var date = new Date();
  date.setDate(date.getDate() + days);
  return date;
};

export const stringAvatar = (name) => {
  return `${name || ""}`
    .replace(/[^a-zA-Z ]/g, "")
    .split(" ")
    .filter((x) => x)
    .map((nameWord) => {
      return nameWord[0];
    })
    .join("");
};

export const isEmail = (email) => {
  const emailRegExp = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
  return emailRegExp.test(`${email || ""}`.toLowerCase());
};

export const downloadImage = async (imageSrc, fileName) => {
  window.open(imageSrc);
  // const image = await fetch(imageSrc)
  // const imageBlog = await image.blob()
  // const imageURL = URL.createObjectURL(imageBlog)

  // const link = document.createElement('a')
  // link.href = imageURL
  // link.download = `${fileName ? fileName : new Date().getTime()}`
  // document.body.appendChild(link)
  // link.click()
  // document.body.removeChild(link)
};

export const ucwords = (str) => {
  return (str + "").replace(/^([a-z])|\s+([a-z])/g, ($1) => {
    return $1.toUpperCase();
  });
};

export const randomNum = () => {
  return Math.floor(Math.random() * 90000) + 10000;
};

export const generateUniqueFileName = () => {
  const timestamp = Date.now();
  const randomString = Math.random()
    .toString(36)
    .replace(/[^a-z0-9]+/g, "")
    .substr(2, 10);
  return `${timestamp}${randomString}`;
};

export const getFileExtension = (fileName) => {
  const ary = `${fileName || ""}`.split(".");
  if (ary.length > 0) {
    return `.${ary[ary.length - 1]}`;
  }
  return "";
};

export const timeSince = (date) => {
  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerYear = msPerDay * 365;
  const elapsed = Date.now() - new Date(date);
  if (elapsed < msPerMinute) {
    return Math.round(elapsed / 1000) + " seconds ago";
  } else if (elapsed < msPerHour) {
    return Math.round(elapsed / msPerMinute) + " minutes ago";
  } else if (elapsed < msPerDay) {
    return Math.round(elapsed / msPerHour) + " hours ago";
  } else if (elapsed < msPerYear) {
    return Math.round(elapsed / msPerDay) + " days ago";
  } else {
    return Math.round(elapsed / msPerYear) + " years ago";
  }
};

export const charValidate = (text, limit) => {
  if (text !== undefined && limit !== undefined) {
    if (text !== null) {
      if (text.length > limit) {
        return `${text.substring(0, limit - 2)}..`;
      } else {
        return `${text.substring(0, limit)}`;
      }
    } else {
      return "";
    }
  }
};

export const getFileNameFromFirebaseUrl = (fileUrl) => {
  const url = decodeURIComponent(
    fileUrl.substring(fileUrl.lastIndexOf("/") + 1, fileUrl.lastIndexOf("?")),
  );
  let splitArr = url.split("/");
  let lastIndex = splitArr.length - 1;
  let fileName = splitArr[lastIndex];
  return fileName || "";
};

export const capitalizeFirstLetter = (string) => {
  const lowercaseString = string.toLowerCase();
  const capitalizedString =
    lowercaseString.charAt(0).toUpperCase() + lowercaseString.slice(1);
  return capitalizedString;
};

export const createFirebaseUrl = (org, type, userId, fileType, fileName) => {
  let environment = "Stage";
  let webUrl = window.location.href;
  if (webUrl.includes("localhost")) {
    environment = "Local";
  } else if (webUrl.includes("stage")) {
    environment = "Stage";
  } else {
    environment = "Production";
  }
  let url;
  org = capitalizeFirstLetter(org) || "Scriv";
  type = type || "FilmRoom";
  userId = userId;
  fileType = fileType || "Thumbnail";
  url = `${org}/${environment}/${userId}/${type}/${fileType}/${fileName}`;
  return url;
};

export const timeFormat = (reqTime) => {
  const startHour = parseInt(reqTime.split(":")[0]);
  const startMinute = parseInt(reqTime.split(":")[1]);
  const isAM = startHour < 12;
  let displayHour = startHour % 12;
  displayHour = displayHour === 0 ? 12 : displayHour;
  const displayTime = `${displayHour}:${startMinute
    .toString()
    .padStart(2, "0")} ${isAM ? "AM" : "PM"}`;
  return displayTime;
};

export const timeFormatWithDate = (date) => {
  const formattedDate = new Date(date).toLocaleString(undefined, {
    hour: "numeric",
    minute: "numeric",
    hour12: true, // Set to true for 12-hour format
  });
  return formattedDate;
};

export const generateBlobFromImgUrl = async (imgUrl) => {
  if (imgUrl) {
    let blobUrl = await fetch(imgUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        return url;
      });
    return blobUrl;
  }
};

export const formatPhoneNumber = (phoneNumber) => {
  // Remove all non-digit characters from the input
  const cleaned = phoneNumber.replace(/\D/g, "");

  if (cleaned.length === 10) {
    return `(${cleaned.substring(0, 3)})-${cleaned.substring(
      3,
      6,
    )}-${cleaned.substring(6)}`;
  }

  return phoneNumber;
};

export const formatCurrency = (amount = 0, currency, isIntegerOnly) => {
  amount = isNaN(amount) ? 0 : amount;
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency ? currency : "USD",
    minimumFractionDigits: isIntegerOnly ? 0 : 2,
  });
  return formatter.format(amount);
};

export const formatDate = (
  date,
  formatString,
  addTimeZoneOffset = false,
  shouldConvertToCST = false,
) => {
  if (!date) return "";
  try {
    const dt = new Date(date);
    if (shouldConvertToCST) {
      let usaTime = dt.toLocaleString("en-US", {
        timeZone: "CST",
      });
      return format(
        new Date(usaTime),
        formatString ? formatString : "MMM dd yyyy",
      );
    }
    const dtDateOnly = new Date(
      addTimeZoneOffset
        ? dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000
        : dt,
    );
    return format(dtDateOnly, formatString ? formatString : "MMM dd yyyy");
  } catch (e) {
    return "";
  }
};

export const convertToCapsOfFirstLetter = (e, formik, fieldName) => {
  const updatedVal =
    e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
  // Update the Formik field value
  formik.setFieldValue(fieldName, updatedVal);
};

export const convertToEmbedURL = (inputURL) => {
  try {
    const url = new URL(inputURL);
    //const videoId = url.searchParams.get('v');
    let videoId = "";

    // Check if it's a standard YouTube URL
    if (url.hostname === "www.youtube.com") {
      videoId = url.searchParams.get("v");
    } else if (url.hostname === "youtu.be") {
      // If it's a shortened URL, extract video ID from the path
      videoId = url.pathname.split("/").pop();
    }
    if (videoId) {
      return `https://www.youtube.com/embed/${videoId}`;
    } else {
      return url;
    }
  } catch (error) {
    return "Invalid URL";
  }
};

export const getYoutubeThumbnail = (inputURL) => {
  try {
    const url = new URL(inputURL);
    let videoId = "";

    // Check if it's a standard YouTube URL
    if (url.hostname === "www.youtube.com") {
      videoId = url.searchParams.get("v");
    } else if (url.hostname === "youtu.be") {
      // If it's a shortened URL, extract video ID from the path
      videoId = url.pathname.split("/").pop();
    }

    // const videoId = url.searchParams.get('v');
    if (videoId) {
      return `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;
    } else {
      return "https://aiforum.org.nz/wp-content/themes/wp-futurelab-child/assets/img/empty-member.png";
    }
  } catch (error) {
    return "Invalid URL";
  }
};

export const getFormattedTime = (timeString) => {
  // Split the string into hours, minutes, and AM/PM parts
  var timeArray = timeString.split(/[:\s]/);
  // Extract hours and convert to 24-hour format
  var hours = parseInt(timeArray[0], 10);
  if (timeArray[2] === "PM" && hours !== 12) {
    hours += 12;
  } else if (timeArray[2] === "AM" && hours === 12) {
    hours = 0;
  }
  // Get minutes
  var minutes = parseInt(timeArray[1], 10);
  // Format the result
  var formattedTime = ("0" + hours).slice(-2) + ":" + ("0" + minutes).slice(-2);
  return formattedTime;
};

export const downloadFile = (url) => {
  const link = document.createElement("a");
  link.href = url;
  link.target = "_blank";
  link.download = ""; // You can specify the desired filename here

  document.body.appendChild(link);
  link.click();

  // Remove the link from the document after download
  document.body.removeChild(link);
};

export const parseDateWithoutTimezone = (date) => {
  return parse(date, "yyyy-MM-dd", new Date());
};
