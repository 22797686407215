import {
  Box,
  Typography,
  useTheme,
  Grid,
  FormHelperText,
  Select,
  MenuItem,
  OutlinedInput,
  Button,
  FormControlLabel,
  Divider,
  Tooltip,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { useCallback, useState, useEffect, useMemo } from "react";
import { CustomInputLabel } from "../../../../components/Common/Inputs/InputLabel";
import CustomTextField from "../../../../components/CustomTextField";
import * as Yup from "yup";
import { useFormik } from "formik";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  addDaysToCurrentDate,
  contentDateFormat,
  parseDateWithoutTimezone,
} from "../../../../utils/functions";
import {
  SET_TOAST,
  getAllTimezoneAction,
  getAllEventSprintAction,
  SET_SUCCESS_ERROR_POPUP,
} from "../../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { forwardRef } from "react";
import ReactQuill from "react-quill";
import "../../../../../node_modules/react-quill/dist/quill.snow.css";
import { LIMIT_VALIDATION } from "../../../../utils/constants";
import { CALENDAR_PAGE } from "../../../../routes/constants";
import { useNavigate } from "react-router";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import RecurringSelection from "./RecurringSelection";
import CustomSwitch from "../../../../components/Common/Inputs/Switch";
import moment from "moment";
import { useMutation } from "react-query";
import AddSprint from "./AddSprint";
import { format, parseISO } from "date-fns";
import CancelConfirmDialog from "../../../../components/Common/CancelConfirmDialog";

export default function CreateCampStep1({
  onNext,
  step,
  selectedEvent,
  handleCancel,
  doNotshowSprint,
  eventType,
  isEditEvent,
  setShowCancelConfirmPopup,
}) {
  const selectionInputStyle = {
    width: "100%",
    minWidth: "100%",
    padding: "12px 14px",
  };
  const ITEM_HEIGHT = 35;
  const ITEM_PADDING_TOP = 5;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 300,
      },
    },
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector((state) => state);
  const step1Details = state?.createCampEvent?.step1Details;

  const recurringOpts = [
    { id: 1, name: "Daily" },
    { id: 2, name: "Weekly" },
    { id: 3, name: "Monthly" },
    { id: 4, name: "Monday to Friday" },
  ];

  const recurringDays = [
    { id: 1, label: "Monday", value: "Mon" },
    { id: 2, label: "Tuesday", value: "Tue" },
    { id: 3, label: "Wednesday", value: "Wed" },
    { id: 4, label: "Thursday", value: "Thu" },
    { id: 5, label: "Friday", value: "Fri" },
    { id: 6, label: "Saturday", value: "Sat" },
    { id: 7, label: "Sunday", value: "Sun" },
  ];

  const theme = useTheme();
  const [dates, setDates] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [showRecurringModal, setShowRecurringModal] = useState(false);
  const [showDateSelectionError, setShowDateSelectionError] = useState(false);
  const [showRecurringDateSelectionError, setShowRecurringDateSelectionError] =
    useState(false);
  const [showRecurringDaySelectionError, setShowRecurringDaySelectionError] =
    useState(false);
  const [selectedRecurringOpt, setSelectedRecurringOpt] = useState([]);
  const [dateSelectionOpt, setDateSelectionOpt] = useState(false);
  const [recurringTypeSelectionOpt, setRecurringTypeSelectionOpt] =
    useState(false);
  const [timezones, setTimezones] = useState([]);
  const [eventSprints, setEventSprints] = useState([]);
  const [selectedRecurringDays, setSelectedRecurringDays] = useState([]);
  const [showAddSprintModal, setShowAddSprintModal] = useState(false);
  const [selectedSprintObj, setSelectedSprintObj] = useState(null);

  const { mutateAsync: getAllTimezone } = useMutation(getAllTimezoneAction);
  const { mutateAsync: getAllEventSprint } = useMutation(
    getAllEventSprintAction,
  );

  const validationSchema = Yup.object({
    campName: Yup.string().trim().required("Event name is required"),
    timezone: Yup.string().required("Time zone is required"),
    description: Yup.string().trim().required("Description is required"),
    startTime: Yup.string().trim().required("Start time selection is required"),
    endTime: Yup.string().trim().required("End time selection is required"),
    event_sprint: Yup.string().required("Sprint selection is required"),
  });
  const inputLabelStyle = { fontSize: 12, marginBottom: "5px", color: "gray" };
  const minSelectableDate = addDaysToCurrentDate(0);
  const minPastDate = new Date();
  minPastDate.setDate(minPastDate.getDate() - 1);

  const formik = useFormik({
    initialValues: {
      campName: step1Details?.campName ?? "",
      startTime: step1Details?.startTime ?? "",
      endTime: step1Details?.endTime ?? "",
      timezone: step1Details?.timezone ?? "EST",
      description: step1Details?.description ?? "",
      event_sprint: step1Details?.event_sprint ?? "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (!dates?.length) {
        setShowDateSelectionError(true);
        return;
      }
      if (eventType === "DAILY_TRAINING_EVENT" || eventType === "MISC_EVENT") {
        if (!selectedDates?.length && recurringTypeSelectionOpt) {
          setShowRecurringDateSelectionError(true);
          return;
        }
        if (!selectedRecurringDays?.length && !recurringTypeSelectionOpt) {
          setShowRecurringDaySelectionError(true);
          return;
        }
      }
      let isStartEndTimeCorrect = checkStartEndTIme();
      // if(!selectedRecurringOpt?.length) {
      //   dispatch({
      //     type: SET_TOAST,
      //     data: {
      //         type: "error",
      //         message: "Select recurring options, from Make Recurring selection",
      //     },
      //   });
      //   return;
      // }
      if (isStartEndTimeCorrect) {
        dispatch({
          type: "STEP_1_DETAILS",
          data: {
            ...values,
            dates: dates,
            dateSelectionOpt,
            startDate: moment(dates[0]).format("YYYY-MM-DD"),
            endDate: moment(dates[dates?.length - 1]).format("YYYY-MM-DD"),
            doNotshowSprint,
            recurrence_type:
              eventType === "DAILY_TRAINING_EVENT" || eventType === "MISC_EVENT"
                ? recurringTypeSelectionOpt
                  ? "DATE"
                  : "DAY"
                : "NA",
            recurrence_pattern:
              eventType === "DAILY_TRAINING_EVENT" || eventType === "MISC_EVENT"
                ? renderRecurrencePattern()
                : "",
          },
        });
        onNext();
      }
    },
  });

  const eventSprintValue = formik.values.event_sprint;

  const renderRecurrencePattern = () => {
    if (recurringTypeSelectionOpt && selectedDates?.length) {
      if (typeof selectedDates === "string") {
        return selectedDates;
      } else {
        let dates = selectedDates?.map((date) => new Date(date).getDate());
        dates = dates?.join(",");
        return dates;
      }
    } else {
      return selectedRecurringDays?.join(",");
    }
  };

  useMemo(() => {
    if (eventSprintValue && eventSprints?.length) {
      setDates([]);
      let sprint = eventSprints?.find(
        (sprint) => sprint?.id == eventSprintValue,
      );
      setSelectedSprintObj(sprint);
    }
  }, [eventSprintValue]);

  useEffect(() => {
    getTimezones();
    getAllEventSprints();
  }, []);

  useEffect(() => {
    if (step1Details) {
      setDates(step1Details?.dates ?? []);
      setSelectedDates(step1Details?.recurrence_pattern ?? []);
      setSelectedRecurringOpt(step1Details?.recurring ?? []);
      setDateSelectionOpt(step1Details?.dateSelectionOpt ?? false);
      setRecurringTypeSelectionOpt(
        step1Details?.recurrence_type === "DATE" ? true : false,
      );
      if (step1Details?.recurrence_type === "DAY") {
        if (
          step1Details?.recurrence_pattern &&
          typeof step1Details?.recurrence_pattern === "string"
        ) {
          setSelectedRecurringDays(
            step1Details?.recurrence_pattern?.split(","),
          );
        } else {
          setSelectedRecurringDays([]);
        }
      }
      formik.setFieldValue(
        "campName",
        step1Details?.campName ? step1Details?.campName : "",
      );
      formik.setFieldValue(
        "startTime",
        step1Details?.startTime ? step1Details?.startTime : "",
      );
      formik.setFieldValue(
        "endTime",
        step1Details?.endTime ? step1Details?.endTime : "",
      );
      formik.setFieldValue(
        "timezone",
        step1Details?.timezone ? step1Details?.timezone : "EST",
      );
      formik.setFieldValue(
        "description",
        step1Details?.description ? step1Details?.description : "",
      );
      formik.setFieldValue(
        "event_sprint",
        step1Details?.event_sprint ? step1Details?.event_sprint : "",
      );
    }
  }, [step1Details]);

  const getTimezones = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let response = await getAllTimezone(dispatch, params);
    if (response?.ok) {
      setTimezones(response?.data ?? []);
    } else {
      dispatch({
        type: SET_TOAST,
        data: {
          type: "error",
          message: response.message ?? "Something went wrong",
        },
      });
    }
  };

  const getAllEventSprints = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let response = await getAllEventSprint({ dispatch, params });
    if (response?.ok) {
      setEventSprints(response?.data ?? []);
    } else {
      dispatch({
        type: SET_TOAST,
        data: {
          type: "error",
          message: response.message ?? "Something went wrong",
        },
      });
    }
  };

  const handleCloseSprintModal = (isSubmit) => {
    setShowAddSprintModal(false);
    if (isSubmit) {
      getAllEventSprints();
    }
  };

  const checkStartEndTIme = () => {
    let startDate = moment(
      moment(dates[0]).format("YYYY-MM-DD") + " " + formik.values.startTime,
    );
    let endDate = moment(
      moment(dates[dates?.length - 1]).format("YYYY-MM-DD") +
        " " +
        formik.values.endTime,
    );

    if (endDate.isBefore(startDate)) {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: "End time should be greater than start time",
        },
      });
      return false;
    } else if (endDate.isSame(startDate)) {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: "End time and start time can not be same",
        },
      });
      return false;
    } else {
      return true;
    }
  };

  const dateTimeInputLabel = {
    fontSize: 12,
    fontFamily: "Poppins",
    fontWeight: 500,
    background: theme?.palette?.secondary?.dark,
    width: 110,
    minWidth: 110,
    height: 44,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    zIndex: 1,
  };

  const getHours = useCallback(() => {
    // Function to generate an array of hourly time in 12-hour format with both AM and PM
    const times = [];
    for (let hour = 1; hour <= 12; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        // Formatting the hour
        const formattedHour = hour < 10 ? `0${hour}` : `${hour}`;
        // Formatting the minute
        const formattedMinute = minute < 10 ? `0${minute}` : `${minute}`;
        // Creating the time string in 12-hour format for AM
        const amTime = `${formattedHour}:${formattedMinute} AM`;
        // Adding both AM and PM times to the array
        times.push(amTime);
      }
    }
    for (let hour = 1; hour <= 12; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        // Formatting the hour
        const formattedHour = hour < 10 ? `0${hour}` : `${hour}`;
        // Formatting the minute
        const formattedMinute = minute < 10 ? `0${minute}` : `${minute}`;
        // Creating the time string in 12-hour format for PM
        const pmTime = `${formattedHour}:${formattedMinute} PM`;
        // Adding both AM and PM times to the array
        times.push(pmTime);
      }
    }
    return times;
  }, []);
  const hours = getHours();

  const handleChangeRecurring = (optId) => {
    if (selectedRecurringOpt?.includes(optId)) {
      let ids = selectedRecurringOpt?.filter((id) => id !== optId);
      setSelectedRecurringOpt(ids);
    } else {
      setSelectedRecurringOpt([...selectedRecurringOpt, optId]);
    }
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <Box
      onClick={onClick}
      ref={ref}
      display="flex"
      alignItems="center"
      sx={{ cursor: "pointer" }}
    >
      <Typography
        // htmlFor='datepicker'
        sx={dateTimeInputLabel}
      >
        Choose Dates
      </Typography>
      <Typography
        variant="h5"
        sx={{
          border: `1px solid #bdbdbd`,
          borderTopRightRadius: 3,
          borderBottomRightRadius: 3,
          height: 44,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: theme?.palette?.grey["50"],
        }}
      >
        {value}
      </Typography>
    </Box>
  ));

  const handleQuillChange = (content) => {
    formik.setFieldValue("description", content);
  };

  const handleChangeEventDays = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedRecurringDays(
      typeof value === "string" ? value.split(",") : value,
    );
    setShowRecurringDaySelectionError(false);
  };

  const disabledTimeFields = () => {
    if (isEditEvent) {
      if (selectedEvent.buttonType === "duplicate") {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
    // if(selectedEvent) {
    //   if(selectedEvent?.class_type === "dailytraining" || selectedEvent?.class_type === "misc") {
    //     if(selectedEvent?.event_type === "sub_event") {
    //       return false;
    //     } else {
    //       return true;
    //     }
    //   } else {
    //     return false;
    //   }
    // } else {
    //   return false;
    // }
  };

  const resetStates = () => {
    setShowDateSelectionError(false);
    setShowRecurringDateSelectionError(false);
    setShowRecurringDaySelectionError(false);
    setSelectedRecurringOpt([]);
    setDateSelectionOpt(false);
    setRecurringTypeSelectionOpt(false);
    setTimezones([]);
    setEventSprints([]);
    setSelectedRecurringDays([]);
    dispatch({
      type: "STEP_1_DETAILS",
      data: null,
    });
    handleCancel();
  };

  return (
    <Box p={3} mt={1}>
      <Typography
        variant="h6"
        sx={{
          color: theme?.palette?.dark?.darkTextSubHeader,
          fontSize: 15,
          fontFamily: "Poppins",
          fontWeight: 500,
          [theme.breakpoints.down("md")]: {
            fontSize: 14,
          },
        }}
      >
        Enter your event details below. You will be guided through a few steps
        to create your event. At anytime, you can select the step you’d like to
        go back to without losing any information.
      </Typography>
      <Box mt={3}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item lg={4} md={12} xs={12}>
              <>
                <CustomInputLabel htmlFor="athlete-school" sx={inputLabelStyle}>
                  Event NAME
                </CustomInputLabel>
                <CustomTextField
                  name="campName"
                  placeholder="Enter name of event here..."
                  variant="outlined"
                  size="small"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.campName}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      border: `1px solid ${theme?.palette?.grey?.[400]}`,
                      width: "100%",
                      boxShadow: "none",
                    },
                    width: "100%",
                  }}
                />
                {Boolean(formik.touched.campName && formik.errors.campName) && (
                  <FormHelperText error>
                    {formik.errors.campName}
                  </FormHelperText>
                )}
              </>
            </Grid>

            <Grid
              item
              lg={4}
              md={6}
              xs={12}
              mt={3}
              sx={{
                [theme.breakpoints.down("lg")]: {
                  mt: 0,
                },
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                sx={{ width: "100%", position: "relative" }}
              >
                <Typography variant="h6" sx={dateTimeInputLabel}>
                  Start Time
                </Typography>
                <Select
                  name="startTime"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.startTime}
                  input={<OutlinedInput sx={selectionInputStyle} />}
                  MenuProps={MenuProps}
                  inputProps={{
                    "aria-label": "Without label",
                    style: {
                      width: "100%",
                    },
                  }}
                  sx={{
                    width: "100%",
                    padding: "0 0 0 110px",
                    "& .MuiOutlinedInput-input": {
                      padding: "12px 14px",
                      minWidth: "100%",
                      width: "100%",
                      boxSizing: "border-box",
                    },
                  }}
                  displayEmpty
                  renderValue={(value) => {
                    if (value) {
                      return value;
                    } else {
                      return (
                        <Typography
                          variant="p"
                          fontWeight={400}
                          fontSize={14}
                          sx={{ opacity: 0.5 }}
                        >
                          Select start time...
                        </Typography>
                      );
                    }
                  }}
                  disabled={disabledTimeFields()}
                >
                  {hours && hours?.length
                    ? hours?.map((hour) => (
                        <MenuItem key={hour} value={hour}>
                          {hour}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </Box>
              {Boolean(formik.touched.startTime && formik.errors.startTime) && (
                <FormHelperText error>{formik.errors.startTime}</FormHelperText>
              )}
            </Grid>
            <Grid
              item
              lg={4}
              md={6}
              xs={12}
              mt={3}
              sx={{
                [theme.breakpoints.down("lg")]: {
                  mt: 0,
                },
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                sx={{ width: "100%", position: "relative" }}
              >
                <Typography variant="h6" sx={dateTimeInputLabel}>
                  End Time
                </Typography>
                <Select
                  name="endTime"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.endTime}
                  input={<OutlinedInput sx={selectionInputStyle} />}
                  MenuProps={MenuProps}
                  inputProps={{
                    "aria-label": "Without label",
                    style: {
                      width: "100%",
                    },
                  }}
                  sx={{
                    width: "100%",
                    padding: "0 0 0 110px",
                    "& .MuiOutlinedInput-root": {
                      minWidth: "100%",
                      width: "100%",
                      boxSizing: "border-box",
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "12px 14px",
                      minWidth: "100%",
                      width: "100%",
                      boxSizing: "border-box",
                    },
                  }}
                  displayEmpty
                  renderValue={(value) => {
                    if (value) {
                      return value;
                    } else {
                      return (
                        <Typography
                          variant="p"
                          fontWeight={400}
                          fontSize={14}
                          sx={{ opacity: 0.5 }}
                        >
                          Select end time...
                        </Typography>
                      );
                    }
                  }}
                  disabled={disabledTimeFields()}
                >
                  {hours && hours?.length
                    ? hours?.map((hour) => (
                        <MenuItem key={hour} value={hour}>
                          {hour}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </Box>
              {Boolean(formik.touched.endTime && formik.errors.endTime) && (
                <FormHelperText error>{formik.errors.endTime}</FormHelperText>
              )}
            </Grid>

            <Grid item lg={4} md={6} xs={12}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={10}>
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{ width: "100%", position: "relative" }}
                  >
                    <Typography variant="h6" sx={dateTimeInputLabel}>
                      Sprint
                    </Typography>
                    <Select
                      name="event_sprint"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.event_sprint}
                      input={<OutlinedInput sx={selectionInputStyle} />}
                      MenuProps={MenuProps}
                      inputProps={{
                        "aria-label": "Without label",
                        style: {
                          width: "100%",
                        },
                      }}
                      sx={{
                        width: "100%",
                        padding: "0 0 0 110px",
                        "& .MuiOutlinedInput-root": {
                          minWidth: "100%",
                          width: "100%",
                        },
                        "& .MuiOutlinedInput-input": {
                          minWidth: "100%",
                          width: "100%",
                          boxSizing: "border-box",
                          padding: "12px 14px",
                        },
                      }}
                      displayEmpty
                      renderValue={(value) => {
                        if (value) {
                          let sprint = eventSprints?.find(
                            (es) => es?.id === value,
                          );
                          if (sprint) {
                            return (
                              <Tooltip title={sprint?.name}>
                                {sprint?.name}
                              </Tooltip>
                            );
                          }
                        } else {
                          return (
                            <Typography
                              variant="p"
                              fontWeight={400}
                              fontSize={14}
                              sx={{ opacity: 0.5 }}
                            >
                              Select sprint...
                            </Typography>
                          );
                        }
                      }}
                      disabled={disabledTimeFields()}
                    >
                      {eventSprints?.map((eventSprint) => (
                        <MenuItem
                          value={eventSprint?.id}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "baseline",
                            px: 2,
                            pb: 0.5,
                          }}
                          key={eventSprint?.id}
                        >
                          <Typography
                            variant="p"
                            fontWeight={400}
                            fontSize={14}
                          >
                            {eventSprint?.name}
                          </Typography>
                          <Typography
                            variant="p"
                            fontWeight={400}
                            fontSize={14}
                            color={theme?.palette?.warning?.blue}
                            mt={0.3}
                          >
                            (
                            {`${format(
                              parseISO(eventSprint?.start_date),
                              "MM-dd-yyyy",
                            )} to ${format(
                              parseISO(eventSprint?.end_date),
                              "MM-dd-yyyy",
                            )}`}
                            )
                          </Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Button
                      size="small"
                      variant="contained"
                      sx={{
                        width: "100%",
                        height: 44,
                        m: 0,
                        p: 0,
                        minWidth: "inherit",
                        fontSize: 20,
                        background: theme?.palette?.secondary?.dark,
                        color: "white",
                      }}
                      onClick={() => setShowAddSprintModal(true)}
                    >
                      +
                    </Button>
                  </Box>
                </Grid>
              </Grid>
              {Boolean(
                formik.touched.event_sprint && formik.errors.event_sprint,
              ) && (
                <FormHelperText error>
                  {formik.errors.event_sprint}
                </FormHelperText>
              )}
            </Grid>

            <Grid
              item
              lg={4}
              md={12}
              xs={12}
              hidden={eventSprintValue ? false : true}
            >
              {/* <CustomInputLabel htmlFor="athlete-school"></CustomInputLabel> */}
              <Box display="flex" alignItems="center">
                <div className="camp_datepicker_container">
                  {/* <ReactDatePicker
                    // id='datepicker'
                    selected={formik.values.start_date}
                    onChange={onMultidayChange}
                    startDate={formik.values.start_date}
                    endDate={formik.values.end_date}
                    selectsRange
                    //inline
                    minDate={minSelectableDate}
                    customInput={<ExampleCustomInput />}
                    style={{ width: "100%" }}
                  /> */}
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{
                      cursor: "pointer",
                      width: "100%",
                      position: "relative",
                    }}
                  >
                    <Typography
                      // htmlFor='datepicker'
                      sx={{
                        ...dateTimeInputLabel,
                        wordBreak: "break-word",
                        p: 2,
                      }}
                    >
                      Date Range
                    </Typography>
                    {
                      // selectedEvent?.id ?
                      //   <DatePicker
                      //     value={dates}
                      //     onChange={(data, date) => {
                      //       if (data && date?.validatedValue) {
                      //         setDates(date?.validatedValue ?? []);
                      //         setShowDateSelectionError(false);
                      //       }
                      //     }}
                      //     name="dates"
                      //     format="MMMM DD YYYY"
                      //     sort
                      //     inputClass="multi_select_datepicker"
                      //     placeholder="Choose dates..."
                      //     // minDate={minPastDate}
                      //     range={dateSelectionOpt}
                      //   // plugins={[
                      //   //   <DatePanel />
                      //   // ]}
                      //   /> :
                      <DatePicker
                        value={dates}
                        editable={false}
                        onChange={(data, date) => {
                          if (date?.validatedValue) {
                            setDates(date?.validatedValue ?? []);
                            setSelectedDates([]);
                            setShowDateSelectionError(false);
                          }
                        }}
                        name="dates"
                        format="MMMM DD YYYY"
                        sort
                        inputClass="multi_select_datepicker"
                        placeholder="Choose date range..."
                        // minDate={minPastDate}
                        minDate={
                          selectedEvent
                            ? parseDateWithoutTimezone(
                                selectedEvent?.event_sprint?.start_date,
                              )
                            : selectedSprintObj
                            ? parseDateWithoutTimezone(
                                selectedSprintObj?.start_date,
                              )
                            : new Date()
                        }
                        maxDate={
                          selectedEvent
                            ? parseDateWithoutTimezone(
                                selectedEvent?.event_sprint?.end_date,
                              )
                            : selectedSprintObj
                            ? parseDateWithoutTimezone(
                                selectedSprintObj?.end_date,
                              )
                            : new Date()
                        }
                        range={true}
                        disabled={disabledTimeFields()}
                        // plugins={[
                        //   <DatePanel />
                        // ]}
                      />
                    }
                  </Box>
                </div>
              </Box>
              {showDateSelectionError && (
                <FormHelperText error>
                  Date selection is required
                </FormHelperText>
              )}
              {/* <FormControlLabel
                sx={{ ml: 0.3, mt: 1 }}
                control={
                  <CustomSwitch
                    name={"dateSelectionOpt"}
                    checked={dateSelectionOpt}
                    onChange={(e) => {
                      setDates([]);
                      setDateSelectionOpt(e?.target?.checked);
                    }}
                    sx={{ mr: 1 }}
                  />
                }
                label={"off/on - Multi-Select/Date-Range"}
              /> */}
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <Box
                display="flex"
                alignItems="center"
                sx={{ width: "100%", position: "relative" }}
              >
                <Typography variant="h6" sx={dateTimeInputLabel}>
                  Time Zone
                </Typography>
                <Select
                  name="timezone"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.timezone}
                  input={<OutlinedInput sx={selectionInputStyle} />}
                  MenuProps={MenuProps}
                  inputProps={{
                    "aria-label": "Without label",
                    style: {
                      width: "100%",
                    },
                  }}
                  sx={{
                    width: "100%",
                    padding: "0 0 0 110px",
                    "& .MuiOutlinedInput-root": {
                      minWidth: "100%",
                      width: "100%",
                      boxSizing: "border-box",
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "12px 14px",
                      minWidth: "100%",
                      width: "100%",
                      boxSizing: "border-box",
                    },
                  }}
                  displayEmpty
                  renderValue={(value) => {
                    if (value) {
                      let timezone = timezones?.find((t) => t?.value === value);
                      if (timezone) {
                        return timezone?.name;
                      }
                    } else {
                      return (
                        <Typography
                          variant="p"
                          fontWeight={400}
                          fontSize={14}
                          sx={{ opacity: 0.5 }}
                        >
                          Select time zone...
                        </Typography>
                      );
                    }
                  }}
                  disabled={disabledTimeFields()}
                >
                  {timezones?.map((zone) => (
                    <MenuItem key={zone?.value} value={zone?.value}>
                      {zone?.name}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              {Boolean(formik.touched.timezone && formik.errors.timezone) && (
                <FormHelperText error>{formik.errors.timezone}</FormHelperText>
              )}
            </Grid>
            {/* <Typography
                variant="h5"
                sx={{
                  width: 0.3,
                  height: 44,
                  background: "transparent",
                  border: `2px solid ${theme?.palette?.secondary?.dark}`,
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: theme?.palette?.secondary?.dark,
                  cursor: "pointer",
                  ml: 3
                }}
                onClick={() => {
                  setShowRecurringModal(true);
                }}
              >
                Make Recurring
              </Typography> */}

            {/* <Box display={"flex"} flexDirection={"column"}>
                {isEditEvent &&
                step1Details?.recurrence_pattern_date && step1Details?.recurrence_pattern_date?.length ?
                step1Details?.recurrence_pattern_date?.map((date) => (
                    <Typography
                      variant="h6"
                      sx={dateTimeInputLabel}
                    >
                      {moment(date).format('L')}
                    </Typography>
                ))
                : null
              }
              </Box> */}

            {((eventType === "DAILY_TRAINING_EVENT" ||
              eventType === "MISC_EVENT") &&
              dates?.length &&
              !isEditEvent) ||
            selectedEvent?.buttonType === "duplicate" ? (
              recurringTypeSelectionOpt ? (
                <Grid item lg={4} md={12} xs={12} mt={1}>
                  <FormControlLabel
                    sx={{ ml: 0.3, mb: 1 }}
                    control={
                      <CustomSwitch
                        name={"recurringTypeSelectionOpt"}
                        checked={recurringTypeSelectionOpt}
                        onChange={(e) => {
                          setSelectedDates([]);
                          setSelectedRecurringDays([]);
                          setRecurringTypeSelectionOpt(e?.target?.checked);
                        }}
                        sx={{ mr: 1 }}
                      />
                    }
                    label={"By Day / By Date"}
                    disabled={disabledTimeFields()}
                  />
                  <Box display="flex" alignItems="center">
                    <div className="camp_datepicker_container">
                      <Box
                        display="flex"
                        alignItems="center"
                        sx={{
                          cursor: "pointer",
                          width: "100%",
                          position: "relative",
                        }}
                      >
                        <Typography
                          sx={{
                            ...dateTimeInputLabel,
                            wordBreak: "break-word",
                            p: 2,
                          }}
                        >
                          Choose Date
                        </Typography>
                        <DatePicker
                          value={selectedDates}
                          editable={false}
                          onChange={(data, date) => {
                            if (data && date?.validatedValue) {
                              setSelectedDates(date?.validatedValue ?? []);
                              setShowRecurringDateSelectionError(false);
                            }
                          }}
                          name="selectedDates"
                          format="MMMM DD YYYY"
                          sort
                          inputClass="multi_select_datepicker"
                          placeholder="Choose date..."
                          minDate={new Date(dates[0])}
                          maxDate={new Date(dates[dates?.length - 1])}
                          range={false}
                          disabled={selectedEvent ? true : false}
                        />
                      </Box>
                    </div>
                  </Box>
                  {showRecurringDateSelectionError && (
                    <FormHelperText error>
                      Date selection is required
                    </FormHelperText>
                  )}
                </Grid>
              ) : (
                <Grid item lg={4} md={12} xs={12} mt={1}>
                  <FormControlLabel
                    sx={{ ml: 0.3, mb: 1 }}
                    control={
                      <CustomSwitch
                        name={"recurringTypeSelectionOpt"}
                        checked={recurringTypeSelectionOpt}
                        onChange={(e) => {
                          setSelectedDates([]);
                          setSelectedRecurringDays([]);
                          setRecurringTypeSelectionOpt(e?.target?.checked);
                        }}
                        sx={{ mr: 1 }}
                      />
                    }
                    label={"By Day / By Date"}
                    disabled={disabledTimeFields()}
                  />
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{
                      cursor: "pointer",
                      width: "100%",
                      position: "relative",
                    }}
                  >
                    <Typography
                      sx={{
                        ...dateTimeInputLabel,
                        wordBreak: "break-word",
                        p: 2,
                      }}
                    >
                      Choose Day
                    </Typography>
                    <Select
                      disabled={disabledTimeFields()}
                      multiple
                      displayEmpty
                      value={selectedRecurringDays}
                      onChange={handleChangeEventDays}
                      input={
                        <OutlinedInput
                          label=""
                          placeholder="Select recurring day..."
                          sx={selectionInputStyle}
                        />
                      }
                      inputProps={{
                        "aria-label": "Without label",
                        style: {
                          width: "100%",
                        },
                      }}
                      sx={{
                        width: "100%",
                        padding: "0 0 0 110px",
                        "& .MuiOutlinedInput-root": {
                          minWidth: "100%",
                          width: "100%",
                        },
                        "& .MuiOutlinedInput-input": {
                          minWidth: "100%",
                          width: "100%",
                          boxSizing: "border-box",
                          padding: "12px 14px",
                        },
                      }}
                      renderValue={(selected) => {
                        if (selected?.length) {
                          let days = [];
                          recurringDays?.filter((day) => {
                            if (selected.includes(day?.value)) {
                              days.push(day?.label);
                            }
                          });
                          if (days?.length) {
                            return days.join(", ");
                          }
                          return selected?.join(", ");
                        } else {
                          return (
                            <Typography
                              variant="p"
                              fontWeight={400}
                              fontSize={14}
                              sx={{ opacity: 0.5 }}
                            >
                              Select recurring day...
                            </Typography>
                          );
                        }
                      }}
                      MenuProps={MenuProps}
                    >
                      {recurringDays?.map((day) => (
                        <MenuItem key={day?.value} value={day?.value}>
                          <Checkbox
                            checked={
                              selectedRecurringDays.indexOf(day?.value) > -1
                            }
                          />
                          <ListItemText primary={day?.label} />
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                  {showRecurringDaySelectionError && (
                    <FormHelperText error>
                      Day selection is required
                    </FormHelperText>
                  )}
                </Grid>
              )
            ) : null}

            {isEditEvent && (
              <Grid item xs={12}>
                {selectedEvent?.class_type === "dailytraining" ||
                selectedEvent?.class_type === "misc" ? (
                  <Box display={"flex"}>
                    <Typography
                      variant="h5"
                      component="div"
                      color={theme?.palette?.warning?.blue}
                      mr={1}
                    >
                      Recurring Date For This Event:-
                    </Typography>
                    {selectedEvent?.recurrence_pattern_date?.map((date) => (
                      <Typography variant="h5" component="div">
                        {`${contentDateFormat(date)}`}
                        ,&nbsp;&nbsp;
                      </Typography>
                    ))}
                  </Box>
                ) : null}
              </Grid>
            )}

            <Grid item xs={12}>
              <CustomInputLabel htmlFor="athlete-school" sx={inputLabelStyle}>
                DESCRIPTION
              </CustomInputLabel>
              <ReactQuill
                theme="snow"
                name="description"
                placeholder="Write something...!"
                modules={CreateCampStep1.modules}
                formats={CreateCampStep1.formats}
                value={formik.values.description}
                onChange={handleQuillChange}
                error={Boolean(
                  formik.touched.description && formik.errors.description,
                )}
                style={{ height: "35vh" }}
              />
              {Boolean(
                formik.touched.description && formik.errors.description,
              ) && (
                <FormHelperText sx={{ mt: 8 }} error>
                  {formik.errors.description}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} mt={6}>
              <Box
                display="flex"
                justifyContent="space-between"
                flexWrap="wrap"
                width={1}
              >
                <Typography
                  variant="h5"
                  sx={{
                    maxWidth: 120,
                    height: 40,
                    background: "transparent",
                    border: `2px solid ${theme?.palette?.secondary?.dark}`,
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: theme?.palette?.secondary?.dark,
                    cursor: "pointer",
                    mr: 2,
                    my: 1,
                    px: 3,
                  }}
                  onClick={() => setShowCancelConfirmPopup(true)}
                >
                  Cancel
                </Typography>
                <Button
                  size="small"
                  variant="contained"
                  type="submit"
                  sx={{
                    maxWidth: 120,
                    height: 40,
                    background: theme?.palette?.secondary?.dark,
                    color: "white",
                    cursor: "pointer",
                    px: 3,
                    my: 1,
                  }}
                  onClick={() => {
                    if (!dates?.length) {
                      setShowDateSelectionError(true);
                      return;
                    }
                    if (!selectedDates?.length && recurringTypeSelectionOpt) {
                      setShowRecurringDateSelectionError(true);
                      return;
                    }
                    if (
                      !selectedRecurringDays?.length &&
                      !recurringTypeSelectionOpt
                    ) {
                      setShowRecurringDaySelectionError(true);
                      return;
                    }
                  }}
                >
                  Next
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
      {showRecurringModal && (
        <RecurringSelection
          open={showRecurringModal}
          handleClose={() => setShowRecurringModal(false)}
          handleSave={(selectedRecurring) => {
            setSelectedRecurringOpt(selectedRecurring);
            setShowRecurringModal(false);
          }}
          selectedRecurringOpt={selectedRecurringOpt}
          recurringOpts={recurringOpts}
          handleChangeRecurring={(id) => handleChangeRecurring(id)}
        />
      )}
      {showAddSprintModal && (
        <AddSprint
          open={showAddSprintModal}
          handleClose={handleCloseSprintModal}
        />
      )}
    </Box>
  );
}

CreateCampStep1.modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link"],
    [{ color: [] }],
    [{ clipboard: true, paste: true }],
  ],
};
CreateCampStep1.formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "color",
];
