import axios from 'axios'
import { store } from '../store';
import { LOGOUT_USER, SET_USER_DRAWER } from '@store/actions'

export const API_BASE_URL = 'https://apis.sketchplay.io'
//export const API_BASE_URL = 'https://stage-apis.sketchplay.io'
//export const API_BASE_URL = 'http://localhost:1337'

const instance = axios.create()

instance.defaults.baseURL = API_BASE_URL
instance.defaults.headers.post['Content-Type'] = 'application/json'
instance.defaults.timeout = 1000 * 30
instance.defaults.timeout = 900000; // 15 minute timeout as 900000 milliseconds - previously 1000*60

// request interceptor
instance.interceptors.request.use(
    (config) => {
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

// response interceptor
instance.interceptors.response.use(
    (response) => {
        return response.data || response
    },
    (error) => {
        if (error.response && error.response.status === 401 && error.response.data?.statusCode === 401 && error.response.data?.message === "User Not Found") {
            // logout user as it is deleted
            store.dispatch({ type: LOGOUT_USER });
            store.dispatch({ type: SET_USER_DRAWER, data: false })

        }

        else if (error.response && error.response.status === 401) {
            // Handle 401 Unauthorized error here
            console.log("Unauthorized request detected")
            // You can redirect to a login page or perform other actions
        }
        return Promise.reject(error)
    }
)

export default instance
