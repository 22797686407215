import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import CustomTextField from "../CustomTextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { CustomInputLabel } from "../Common/Inputs/InputLabel";
import SuccessPopup from "../Common/SuccessPopup";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import StripePayment from "../Tournament/StripePayment";
import { useEffect } from "react";
import {
  SET_LOADER,
  SET_SUCCESS_ERROR_POPUP,
  applyProductDiscountCodeAction,
  applyTeamDiscountCodeAction,
  getUserWalletDetailsAction,
} from "../../store/actions";
import httpClient from "@utils/httpClient";
import { formatCurrency } from "../../utils/functions";
import DeleteConfirmPopup from "../Common/DeleteConfirm";
import { NumericFormat } from "react-number-format";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelConfirmDialog from "../Common/CancelConfirmDialog";

const PackageCheckoutPayment = ({
  open,
  handleClose,
  selectedPackages,
  deleteCartItem,
  applyDiscount,
  setDiscountDetails,
  discountDetails,
  resetSelectedPackIds,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const userType = state?.user?.user?.user_type;
  const scholarshipData = state?.user?.user?.scholarship;
  const selectionInputStyle = { width: "100%", padding: "12px 14px" };
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [paymentFormData, setPaymentFormData] = useState({});
  const [isCouponApplied, setCouponApplied] = useState(
    discountDetails?.isCouponApplied ?? false,
  );

  const [couponAppliedMessage, setCouponAppliedMessage] = useState(
    discountDetails?.couponAppliedMessage ?? "",
  );
  const [couponCodeAmount, setCouponCodeAmount] = useState(
    discountDetails?.couponCodeAmount ?? 0,
  );
  const [isDiscountPayment, setIsDiscountPayment] = useState(
    discountDetails?.isDiscountPayment ?? false,
  );
  const [
    isShowStripeCardDetailsFilledError,
    setShowStripeCardDetailsFilledError,
  ] = useState(false);
  const [isFullDiscount, setIsFullDiscount] = useState(
    discountDetails?.isFullDiscount ?? false,
  );
  const [isApplyScholarshipDiscount, setApplyScholarshipDiscount] = useState(
    discountDetails?.isApplyScholarshipDiscount ?? false,
  );
  const [isAppliedSKBucks, setIsAppliedSKBucks] = useState(
    discountDetails?.isAppliedSKBucks ?? false,
  );
  const [scholarshipDiscountValue, setScholarshipDiscountValue] = useState(
    discountDetails?.scholarshipDiscountValue ?? 0,
  );
  const [SKBucksDiscountValue, setSKBucksDiscountValue] = useState(0);
  const [originalAmountValue, setOriginalAmountValue] = useState(
    discountDetails?.originalAmountValue ?? 0,
  );
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [packRow, setPackRow] = useState({});
  const [SKBucks, setSKBucks] = useState(
    discountDetails?.isAppliedSKBucks
      ? discountDetails?.SKBucksFormatted ?? ""
      : "",
  );
  const [SKBucksBalance, setSKBucksBalance] = useState(null);
  const [totalDiscountedValue, setTotalDiscountedValue] = useState(
    discountDetails?.totalDiscountedValue ?? 0,
  );
  const [paymentStep, setPaymentStep] = useState(1);
  const [showCancelConfirmPopup, setShowCancelConfirmPopup] = useState(false);
  const [showCancelConfirmPaymentPopup, setShowCancelConfirmPaymentPopup] =
    useState(false);
  const [checkDiscountResponse, setCheckDiscountResponse] = useState();
  const [isTrueDiscountCode, setIsTrueDiscountCode] = useState(false);

  const ITEM_HEIGHT = 35;
  const ITEM_PADDING_TOP = 5;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const boldFont = { fontWeight: 700 };
  const couponAppliedMessageTextStyle = {
    color: "green",
    marginTop: 10,
    fontSize: 14,
    marginBottom: 0,
    padding: "0 24px",
  };
  const resetDiscountIconStyle = {
    color: "red",
    cursor: "pointer",
    position: "absolute",
    right: "8px",
  };
  const numericInputStyle = {
    padding: "1px",
    borderRadius: "4px",
    border: `1px solid ${theme?.palette?.grey?.[400]}`,
    width: "100%",
    boxShadow: "none",
    outline: "none",
    height: "45px",
    background: "#fafafa",
    padding: "10px",
  };
  const discountMessageIconStyle = {
    marginRight: "8px",
  };

  function createData(
    package_name,
    pack_name,
    discount_amount,
    amount,
    total_amount_after_discount,
    product_id,
  ) {
    return {
      package_name,
      pack_name,
      discount_amount,
      amount,
      total_amount_after_discount,
      product_id,
    };
  }

  const rows = [];
  let totalAmount = discountDetails?.totalAmount ?? 0;
  let totalAmountSKBucks = discountDetails?.totalAmount ?? 0;

  if (selectedPackages && selectedPackages?.length) {
    totalAmount = selectedPackages.reduce(
      (total, item) => total + item.pack.total_amount_after_discount,
      0,
    );
    totalAmountSKBucks = selectedPackages.reduce(
      (total, item) => item.pack.totalAmountSKBucks,
      0,
    );
    selectedPackages?.forEach((packValue) => {
      rows.push(
        createData(
          packValue?.selectedPackage?.name,
          packValue?.pack?.title,
          packValue?.pack?.discount_amount,
          packValue?.pack?.amount,
          packValue?.pack?.total_amount_after_discount,
          packValue?.packageId,
        ),
      );
    });
  }

  let mainTotalAmount = useMemo(() => {
    if (selectedPackages && selectedPackages?.length) {
      return selectedPackages.reduce(
        (total, item) => total + item.pack.amount,
        0,
      );
    }
  }, [selectedPackages]);

  const validationSchema = Yup.object({
    description: Yup.string().trim(),
    paymentMethod: Yup.string().required(
      "Payment method selection is required",
    ),
  });

  const formik = useFormik({
    initialValues: {
      description: "",
      paymentMethod: "Stripe",
      discountCode: discountDetails?.couponCode ?? "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (values?.paymentMethod === "Stripe") {
        completeStripePaymentProcess();
      } else {
        completeAdminPaymentProcess(values?.description);
      }
    },
  });

  useEffect(() => {
    if (discountDetails) {
      formik.setFieldValue("discountCode", discountDetails?.couponCode ?? "");
      setIsAppliedSKBucks(discountDetails?.isAppliedSKBucks ?? false);
      setApplyScholarshipDiscount(
        discountDetails?.isApplyScholarshipDiscount ?? false,
      );
      setScholarshipDiscountValue(
        discountDetails?.scholarshipDiscountValue ?? 0,
      );
      if (discountDetails?.isAppliedSKBucks) {
        setSKBucks(discountDetails?.SKBucksFormatted ?? "");
      } else {
        setSKBucks("");
      }
    }
  }, [discountDetails]);

  let SKBucksFormatted = useMemo(() => {
    if (SKBucks && typeof SKBucks === "string") {
      return parseFloat(SKBucks.replace(/[^0-9.]/g, ""));
    } else {
      if (typeof SKBucks === "number") {
        return SKBucks;
      } else {
        return 0;
      }
    }
  }, [SKBucks]);

  const paymentMethod = formik.values.paymentMethod;
  let couponCode = formik?.values?.discountCode ?? "";

  useEffect(() => {
    localStorage.setItem("isStripeCardDetailsFilled", false);
    getUserWalletDetails();
    return () => {
      localStorage.removeItem("isStripeCardDetailsFilled");
    };
  }, []);

  const getUserWalletDetails = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let response = await getUserWalletDetailsAction(params, dispatch);
    if (response?.ok) {
      setSKBucksBalance(response?.data?.sketchplay_bucks);
    }
  };

  const individualStripePaymentIntent = async (
    selectedPackagesList,
    appliedForSKBucks,
  ) => {
    if ((!totalAmount && !totalAmountSKBucks) || isFullDiscount) {
      return;
    }

    let values = {
      payment: [],
      transaction_id: isFullDiscount ? "free" : paymentFormData.transaction_id,
      total_amount: isFullDiscount
        ? 0
        : checkDiscountResponse
        ? checkDiscountResponse?.data?.total_amount
        : 0,
      discount: checkDiscountResponse
        ? checkDiscountResponse?.data?.discount
        : "",
      discount_value: checkDiscountResponse
        ? checkDiscountResponse?.data?.discount_value
        : 0,
      apply_scholarship: checkDiscountResponse
        ? checkDiscountResponse?.data?.apply_scholarship
        : false,
      scholarship_amount: checkDiscountResponse
        ? checkDiscountResponse?.data?.scholarship_amount
        : 0,
      discount_code_value: checkDiscountResponse
        ? checkDiscountResponse?.data?.discount_code_value
        : 0,

      original_amount: checkDiscountResponse
        ? checkDiscountResponse?.data?.original_amount
        : 0,
      isFullDiscount: checkDiscountResponse
        ? checkDiscountResponse?.data?.isFullDiscount
        : false,
      sketchplay_bucks: checkDiscountResponse
        ? checkDiscountResponse?.data?.sketchplay_bucks
        : 0,
    };

    if (selectedPackages?.length) {
      selectedPackages?.forEach((packageVal) => {
        values.payment.push({
          product_id: packageVal?.packageId,
          pack_id: packageVal?.pack?.id,
          total_amount: packageVal?.pack?.amount,

          sketchplay_bucks: checkDiscountResponse
            ? checkDiscountResponse?.data?.payment[0]?.sketchplay_bucks
            : 0,
          scholarship_amount: checkDiscountResponse
            ? checkDiscountResponse?.data?.payment[0]?.scholarship_amount
            : 0,
          discount_amount: checkDiscountResponse
            ? checkDiscountResponse?.data?.payment[0]?.discount_amount
            : 0,
          remaining_amount: checkDiscountResponse
            ? checkDiscountResponse?.data?.payment[0]?.remaining_amount
            : 0,
          discount_code_value: checkDiscountResponse
            ? checkDiscountResponse?.data?.payment[0]?.discount_code_value
            : 0,
          validScholarship: checkDiscountResponse
            ? checkDiscountResponse?.data?.payment[0]?.validScholarship
            : false,
          original_amount: checkDiscountResponse
            ? checkDiscountResponse?.data?.payment[0]?.original_amount
            : 0,
        });
      });
    }

    const API_URL = "/packPaymentIntent";

    dispatch({ type: SET_LOADER, data: true });

    const apiRes = await httpClient.post(API_URL, values);

    dispatch({ type: SET_LOADER, data: false });

    if (apiRes && apiRes.ok) {
      values.clientSecret = apiRes.data?.clientSecret;
      values.transaction_id = apiRes.data?.transaction_id;
      values.publishableKey = apiRes.data?.publishableKey;
      values.amount = apiRes.data?.amount;
      values.original_amount = apiRes?.original_amount;
      setPaymentFormData(values);
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: apiRes?.message ?? "Something went wrong",
        },
      });
      setPaymentStep(1);
    }
  };

  const completeStripePaymentProcess = async () => {
    const API_URL = "/packPayment";

    let values = {
      payment: [],

      transaction_id: isFullDiscount ? "free" : paymentFormData.transaction_id,
      total_amount: isFullDiscount
        ? 0
        : checkDiscountResponse
        ? checkDiscountResponse?.data?.total_amount
        : 0,
      discount: checkDiscountResponse
        ? checkDiscountResponse?.data?.discount
        : "",
      discount_value: checkDiscountResponse
        ? checkDiscountResponse?.data?.discount_value
        : 0,
      apply_scholarship: checkDiscountResponse
        ? checkDiscountResponse?.data?.apply_scholarship
        : false,
      scholarship_amount: checkDiscountResponse
        ? checkDiscountResponse?.data?.scholarship_amount
        : 0,
      discount_code_value: checkDiscountResponse
        ? checkDiscountResponse?.data?.discount_code_value
        : 0,

      original_amount: checkDiscountResponse
        ? checkDiscountResponse?.data?.original_amount
        : 0,
      isFullDiscount: checkDiscountResponse
        ? checkDiscountResponse?.data?.isFullDiscount
        : false,
      sketchplay_bucks: checkDiscountResponse
        ? checkDiscountResponse?.data?.sketchplay_bucks
        : 0,
    };

    if (selectedPackages?.length) {
      selectedPackages?.forEach((packageVal) => {
        values.payment.push({
          product_id: packageVal?.packageId,
          pack_id: packageVal?.pack?.id,
          total_amount: packageVal?.pack?.amount,

          sketchplay_bucks: checkDiscountResponse
            ? checkDiscountResponse?.data?.payment[0]?.sketchplay_bucks
            : 0,
          scholarship_amount: checkDiscountResponse
            ? checkDiscountResponse?.data?.payment[0]?.scholarship_amount
            : 0,
          discount_amount: checkDiscountResponse
            ? checkDiscountResponse?.data?.payment[0]?.discount_amount
            : 0,
          remaining_amount: checkDiscountResponse
            ? checkDiscountResponse?.data?.payment[0]?.remaining_amount
            : 0,
          discount_code_value: checkDiscountResponse
            ? checkDiscountResponse?.data?.payment[0]?.discount_code_value
            : 0,
          validScholarship: checkDiscountResponse
            ? checkDiscountResponse?.data?.payment[0]?.validScholarship
            : false,
          original_amount: checkDiscountResponse
            ? checkDiscountResponse?.data?.payment[0]?.original_amount
            : 0,
        });
      });
    }

    dispatch({ type: SET_LOADER, data: true });

    const apiRes = await httpClient.post(API_URL, values);

    dispatch({ type: SET_LOADER, data: false });

    if (apiRes && apiRes.ok) {
      setShowSuccessModal(true);
      setSKBucks(apiRes?.sketchplay_bucks);
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: apiRes?.message ?? "Something went wrong",
        },
      });
    }
  };

  const completeAdminPaymentProcess = async (description) => {
    const API_URL = "/adminPackPayment";

    let values = {
      user_id: state?.user?.user?.id,
      payment: [],
      total_amount: isFullDiscount ? 0 : paymentFormData?.amount,
      discount: isCouponApplied ? couponCode : "",
      discount_value: totalDiscountedValue,
      admin_desc: description,
      apply_scholarship: isApplyScholarshipDiscount,
      scholarship_amount: scholarshipDiscountValue ?? 0,
      discount_code_amount: isCouponApplied ? couponCodeAmount : 0,
      original_amount: paymentFormData?.original_amount,
    };

    if (SKBucks && isAppliedSKBucks) {
      if (typeof SKBucks === "string") {
        values["sketchplay_bucks"] = SKBucks
          ? parseFloat(SKBucks.replace(/[^0-9.]/g, ""))
          : "";
      } else {
        values["sketchplay_bucks"] = SKBucks;
      }
    } else {
      values["sketchplay_bucks"] = 0;
    }

    if (selectedPackages?.length) {
      selectedPackages?.forEach((packageVal) => {
        values.payment.push({
          product_id: packageVal?.packageId,
          pack_id: packageVal?.pack?.id,
          total_amount: packageVal?.pack?.amount,
        });
      });
    }

    dispatch({ type: SET_LOADER, data: true });

    const apiRes = await httpClient.post(API_URL, values);

    dispatch({ type: SET_LOADER, data: false });

    if (apiRes && apiRes.ok) {
      setShowSuccessModal(true);
      setSKBucks(apiRes?.sketchplay_bucks);
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: apiRes?.message ?? "Something went wrong",
        },
      });
    }
  };

  const handleSuccessModalClose = () => {
    setCouponCodeAmount(0);
    setCouponApplied(false);
    setCouponAppliedMessage(undefined);
    setShowSuccessModal(false);
    setIsFullDiscount(false);
    handleClose(false);
    setDiscountDetails(undefined);
    setPackRow({});
    setIsAppliedSKBucks(false);
    setSKBucks("");
    setTotalDiscountedValue(0);
    resetSelectedPackIds();
  };

  const deleteCartItems = () => {
    setCouponCodeAmount(0);
    setCouponApplied(false);
    setCouponAppliedMessage(undefined);
    setShowSuccessModal(false);
    setIsFullDiscount(false);
    setIsAppliedSKBucks(false);
    setSKBucks("");
    setTotalDiscountedValue(0);
    setIsDiscountPayment(false);
    setApplyScholarshipDiscount(false);
    formik.setFieldValue("discountCode", "");

    deleteCartItem(packRow, applyCouponCode, false);
    setShowDeletePopup(false);
  };

  const PaymentOverviewTable = () => {
    return (
      <TableContainer
        component={Paper}
        sx={{ border: "1px solid #e0e0e0", overflowY: "auto" }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: "700",
                  whiteSpace: "nowrap",
                  minWidth: "170px",
                }}
                align="left"
              >
                PRODUCT NAME
              </TableCell>
              <TableCell
                sx={{ fontWeight: "700", whiteSpace: "nowrap" }}
                align="left"
              >
                PACK
              </TableCell>
              <TableCell
                sx={{ fontWeight: "700", whiteSpace: "nowrap" }}
                align="right"
              >
                AMOUNT
              </TableCell>
              <TableCell
                sx={{ fontWeight: "700", whiteSpace: "nowrap" }}
                align="right"
              >
                DISCOUNT AMOUNT
              </TableCell>
              <TableCell
                sx={{ fontWeight: "700", whiteSpace: "nowrap" }}
                align="right"
              >
                TOTAL (after discount)
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.package_name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="left">{row.package_name}</TableCell>
                <TableCell align="left">{row.pack_name}</TableCell>
                <TableCell align="right">
                  {formatCurrency(row.amount)}
                </TableCell>
                <TableCell align="right">
                  {formatCurrency(
                    row.discount_amount ? row.discount_amount : 0,
                  )}
                </TableCell>
                <TableCell align="right">
                  {formatCurrency(row.total_amount_after_discount)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const PaymentTotalPreviewTable = () => {
    return (
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          {totalDiscountedValue ? (
            <TableHead>
              <TableRow>
                {(isCouponApplied && isApplyScholarshipDiscount) ||
                couponCodeAmount ||
                isAppliedSKBucks ? (
                  <>
                    <TableCell sx={boldFont} align="left">
                      Discounts:{" "}
                      {scholarshipDiscountValue
                        ? `(scholarship discount - ${formatCurrency(
                            scholarshipDiscountValue,
                          )})`
                        : ""}
                    </TableCell>
                    <TableCell sx={boldFont} align="right">
                      -{formatCurrency(totalDiscountedValue)}
                    </TableCell>
                  </>
                ) : null}
              </TableRow>
            </TableHead>
          ) : null}
          {scholarshipDiscountValue ? (
            <TableBody>
              <TableCell
                sx={{ fontWeight: 700, borderBottom: "none" }}
                align="left"
              >
                Scholarship Discount
              </TableCell>
              <TableCell
                sx={{ fontWeight: 700, borderBottom: "none" }}
                align="right"
              >
                -{formatCurrency(scholarshipDiscountValue)}
              </TableCell>
            </TableBody>
          ) : null}
          {couponCodeAmount ? (
            <TableBody>
              <TableCell
                sx={{ fontWeight: 700, borderBottom: "none" }}
                align="left"
              >
                Coupon Code discount
              </TableCell>
              <TableCell
                sx={{ fontWeight: 700, borderBottom: "none" }}
                align="right"
              >
                -{formatCurrency(couponCodeAmount)}
              </TableCell>
            </TableBody>
          ) : null}
          {SKBucksDiscountValue ? (
            <TableBody sx={{ mt: 0 }}>
              <TableCell
                sx={{ fontWeight: 700, borderBottom: "none" }}
                align="left"
              >
                Sketchplay Bucks
              </TableCell>
              <TableCell
                sx={{ fontWeight: 700, borderBottom: "none" }}
                align="right"
              >
                -{formatCurrency(SKBucksDiscountValue)}
              </TableCell>
            </TableBody>
          ) : null}

          <TableBody>
            <TableCell sx={boldFont} align="left">
              TOTAL:
            </TableCell>
            <TableCell sx={boldFont} align="right">
              {SKBucksFormatted
                ? formatCurrency(totalAmountSKBucks)
                : formatCurrency(totalAmount)}
            </TableCell>
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const resetSKBucksDiscount = () => {
    setSKBucks("");
    setIsAppliedSKBucks(false);
    applyCouponCode(selectedPackages, false, false, 0);
  };

  const resetDiscountCode = () => {
    formik.setFieldValue("discountCode", "");
    setIsDiscountPayment(false);
    setSKBucks(SKBucks);
    setIsAppliedSKBucks(false);
    applyCouponCode(selectedPackages, false, true, SKBucks);
  };

  const applyCouponCode = async (
    selectedPackagesList,
    appliedForSKBucks,
    isClearDiscount,
    SKBucksVal,
    isDeletePack,
  ) => {
    setCouponAppliedMessage(undefined);
    // setTotalDiscountedValue(0);
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };

    let bodyParams = {
      payment: [],
      discount:
        isDeletePack || isClearDiscount
          ? ""
          : couponCode
          ? couponCode
          : discountDetails?.couponCode ?? "",
      apply_scholarship:
        scholarshipData?.length > 0
          ? scholarshipData[0]?.status === "Approved"
            ? true
            : false
          : false,
      // total_amount: isFullDiscount ? originalAmountValue : mainTotalAmount
      total_amount: mainTotalAmount,
    };

    // if (SKBucksVal && (isClearDiscount || appliedForSKBucks)) {
    //   if (isDeletePack || (isApplyScholarshipDiscount && !appliedForSKBucks)) {
    //     bodyParams["sketchplay_bucks"] = 0;
    //   } else {
    //     if (typeof SKBucksVal === "string") {
    //       bodyParams["sketchplay_bucks"] = SKBucksVal
    //         ? parseFloat(SKBucksVal.replace(/[^0-9.]/g, ""))
    //         : "";
    //     } else {
    //       bodyParams["sketchplay_bucks"] = SKBucksVal;
    //     }
    //   }
    // } else {
    //   bodyParams["sketchplay_bucks"] = 0;
    // }

    if (SKBucksVal) {
      if (typeof SKBucksVal === "string") {
        bodyParams["sketchplay_bucks"] = SKBucksVal
          ? parseFloat(SKBucksVal.replace(/[^0-9.]/g, ""))
          : "";
      } else {
        bodyParams["sketchplay_bucks"] = SKBucksVal;
      }
    } else {
      bodyParams["sketchplay_bucks"] = 0;
    }

    if (selectedPackagesList?.length) {
      selectedPackagesList?.forEach((packageVal) => {
        bodyParams.payment.push({
          product_id: packageVal?.packageId,
          pack_id: packageVal?.pack?.id,
          original_amount: packageVal?.pack?.amount, // previously total_amount
        });
      });
    }

    try {
      let response = await applyProductDiscountCodeAction(
        dispatch,
        params,
        bodyParams,
      );

      if (response?.ok) {
        setCheckDiscountResponse(response);
        if (response.dMessage == "Discount is more than total amount.") {
          setIsTrueDiscountCode(true);
        } else {
          setIsTrueDiscountCode(false);
        }
        if (
          response?.message &&
          !response?.isValidDiscount &&
          !response?.isInvalidPayment &&
          !response?.isValidSKDiscount
        ) {
          dispatch({
            type: SET_SUCCESS_ERROR_POPUP,
            data: {
              open: true,
              type: "error",
              message: response?.message ?? "Something went wrong! Try again",
            },
          });
        }
        let data = response?.data ?? "";
        let arr = [];
        if (data) {
          // if (data) {
          setApplyScholarshipDiscount(response?.isValidScholarshipDiscount);
          setCouponCodeAmount(data?.discount_code_value ?? 0);
          setCouponApplied(true);
          setIsFullDiscount(data?.isFullDiscount ?? false);
          setScholarshipDiscountValue(data?.scholarship_amount ?? 0);
          setSKBucksDiscountValue(data?.sketchplay_bucks ?? 0);
          setTotalDiscountedValue(data?.total_discount_amount ?? 0);
          totalAmount = data?.total_amount;
          totalAmountSKBucks = data?.total_amount;
          setOriginalAmountValue(data?.original_amount);

          if (selectedPackagesList?.length && data?.payment?.length) {
            selectedPackagesList?.forEach((packageVal) => {
              data?.payment?.forEach((discount) => {
                if (
                  discount?.product_id === packageVal?.packageId &&
                  discount?.pack_id === packageVal?.pack?.id
                ) {
                  arr?.push({
                    ...packageVal,
                    pack: {
                      ...packageVal?.pack,
                      discount_amount: discount?.discount_amount,
                      total_amount_after_discount: discount?.remaining_amount,
                      // valid: discount?.valid,
                      totalAmountSKBucks: data?.total_amount,
                    },
                  });
                }
              });
            });
          }
          applyDiscount(arr);
        }
      } else {
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: {
            open: true,
            type: "error",
            message: response?.message ?? "Something went wrong! Try again",
          },
        });
      }
    } catch (error) {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: error?.message ?? "Something went wrong! Try again",
        },
      });
    }
  };

  useMemo(() => {
    applyCouponCode(selectedPackages, isAppliedSKBucks, false, SKBucks);
  }, []);

  const handleConfirmClose = () => {
    handleSuccessModalClose();
    handleClose(true);
  };

  const handleCancelPayment = () => {
    setShowCancelConfirmPaymentPopup(true);
  };

  const handleConfirmCancelPayment = () => {
    setShowCancelConfirmPaymentPopup(false);
    setPaymentStep(1);
  };

  const renderDiscountMessage = () => {
    return (
      <Box my={1.5}>
        <Box
          sx={{
            display: checkDiscountResponse?.sMessage ? "flex" : "none",
            my: 0.6,
          }}
          alignItems={"center"}
        >
          {checkDiscountResponse?.isValidScholarshipDiscount ? (
            <CheckCircleIcon
              sx={{ ...discountMessageIconStyle, color: "green" }}
              fontSize="small"
            />
          ) : (
            <CancelIcon
              sx={{ ...discountMessageIconStyle, color: "red" }}
              fontSize="small"
            />
          )}
          <h6
            style={{
              ...couponAppliedMessageTextStyle,
              margin: 0,
              padding: 0,
              color: checkDiscountResponse?.isValidScholarshipDiscount
                ? "green"
                : "red",
            }}
          >
            {checkDiscountResponse?.sMessage}
          </h6>
        </Box>
        <Box
          sx={{
            display: checkDiscountResponse?.dMessage ? "flex" : "none",
            my: 0.6,
          }}
          alignItems={"center"}
        >
          {checkDiscountResponse?.isValidDiscountCode ? (
            <CheckCircleIcon
              sx={{ ...discountMessageIconStyle, color: "green" }}
              fontSize="small"
            />
          ) : (
            <CancelIcon
              sx={{ ...discountMessageIconStyle, color: "red" }}
              fontSize="small"
            />
          )}
          <h6
            style={{
              ...couponAppliedMessageTextStyle,
              margin: 0,
              padding: 0,
              color: checkDiscountResponse?.isValidDiscountCode
                ? "green"
                : "red",
            }}
          >
            {checkDiscountResponse?.dMessage}
          </h6>
        </Box>
        <Box
          sx={{
            display: checkDiscountResponse?.skMessage ? "flex" : "none",
            my: 0.6,
          }}
          alignItems={"center"}
        >
          {checkDiscountResponse?.isValidSKDiscount ? (
            <CheckCircleIcon
              sx={{ ...discountMessageIconStyle, color: "green" }}
              fontSize="small"
            />
          ) : (
            <CancelIcon
              sx={{ ...discountMessageIconStyle, color: "red" }}
              fontSize="small"
            />
          )}
          <h6
            style={{
              ...couponAppliedMessageTextStyle,
              margin: 0,
              padding: 0,
              color: checkDiscountResponse?.isValidSKDiscount ? "green" : "red",
            }}
          >
            {checkDiscountResponse?.skMessage}
          </h6>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Dialog
        open={open}
        keepMounted
        maxWidth="md"
        fullWidth
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            px={0}
            py={1}
            sx={{
              [theme.breakpoints.down("md")]: {
                flexDirection: "column",
                alignItems: "start",
              },
            }}
          >
            <Typography
              variant="h4"
              fontWeight={700}
              fontSize={30}
              color={theme?.palette?.text?.containerTextLabel}
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: 28,
                },
              }}
            >
              Payment
            </Typography>
            <Box
              sx={{
                background: "#EFF7FF",
                border: `1px solid #7FBEFD`,
                color: "black",
                maxWidth: "30rem",
                borderRadius: "7px",
                fontSize: "14px",
                fontWeight: 500,
                ml: 3,
                mt: 2,
                [theme.breakpoints.down("md")]: {
                  maxWidth: "100%",
                  ml: 0,
                },
              }}
              p={2}
            >
              <span style={{ color: "red" }}>*&nbsp;&nbsp;</span>Please be
              patient once submitted. This process could take a several moments
              to complete.
            </Box>
          </Box>
        </DialogTitle>
        <Box width={1} mb={1}>
          <Box
            width={1}
            height={"1px"}
            bgcolor={"gray"}
            sx={{ opacity: 0.25 }}
          ></Box>
        </Box>
        <form onSubmit={formik.handleSubmit}>
          {paymentStep === 1 && (
            <>
              <Box display="flex" justifyContent="center" px={3} mt={2}>
                <PaymentOverviewTable />
              </Box>
              <Box display="flex" justifyContent="center" px={3} mt={2}>
                <PaymentTotalPreviewTable />
              </Box>
              <DialogContent px={3} mt={2}>
                <Grid container spacing={2}>
                  {/* {isCouponApplied ||
                  isApplyScholarshipDiscount ||
                  isAppliedSKBucks
                    ? couponAppliedMessage
                    : null} */}
                  {checkDiscountResponse && (
                    <Grid item xs={12}>
                      {isCouponApplied ||
                      isApplyScholarshipDiscount ||
                      isAppliedSKBucks
                        ? renderDiscountMessage()
                        : null}
                    </Grid>
                  )}
                  {!isDiscountPayment || isCouponApplied ? (
                    <>
                      {userType === "admin" ||
                        (userType === "superadmin" && (
                          <Grid item xs={12}>
                            <CustomInputLabel htmlFor="payment-method">
                              SELECT PAYMENT METHOD (Payment Selection is Only
                              Available for Admins or Super-Admins)
                            </CustomInputLabel>
                            <Select
                              id="payment-method"
                              name="paymentMethod"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.paymentMethod}
                              input={<OutlinedInput sx={selectionInputStyle} />}
                              MenuProps={MenuProps}
                              disabled={
                                userType === "admin" ||
                                userType === "superadmin"
                                  ? false
                                  : true
                              }
                              inputProps={{
                                "aria-label": "Without label",
                                style: {
                                  width: "100%",
                                },
                              }}
                              sx={{
                                width: "100%",
                                "& .MuiOutlinedInput-input": {
                                  padding: "12px 14px",
                                },
                              }}
                              displayEmpty
                              renderValue={(value) => {
                                if (value) {
                                  return value;
                                } else {
                                  return (
                                    <Typography
                                      variant="p"
                                      fontWeight={400}
                                      fontSize={14}
                                      sx={{ opacity: 0.5 }}
                                    >
                                      Select payment method...
                                    </Typography>
                                  );
                                }
                              }}
                            >
                              <MenuItem value={"Stripe"}>Stripe</MenuItem>
                              {userType === "admin" ||
                              userType === "superadmin" ? (
                                <MenuItem value={"Other"}>Other</MenuItem>
                              ) : null}
                            </Select>
                            {Boolean(
                              formik.touched.paymentMethod &&
                                formik.errors.paymentMethod,
                            ) && (
                              <FormHelperText error>
                                {formik.errors.paymentMethod}
                              </FormHelperText>
                            )}
                          </Grid>
                        ))}
                    </>
                  ) : null}

                  {/* : null} */}

                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Box>
                      <Typography
                        variant="p"
                        sx={{
                          color: theme?.palette?.dark?.darkTextSubHeader,
                          fontWeight: 500,
                          fontFamily: "Poppins",
                          mr: 1,
                          mb: 0.5,
                        }}
                      >
                        Discount Code
                      </Typography>
                      <Box
                        display={"flex"}
                        width={1}
                        sx={{ position: "relative" }}
                      >
                        <Box
                          display={"flex"}
                          width={1}
                          sx={{ position: "relative" }}
                        >
                          <CustomTextField
                            name="discountCode"
                            placeholder="Enter discount code..."
                            variant="outlined"
                            size="small"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.discountCode}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                border: `1px solid ${theme?.palette?.grey?.[400]}`,
                                width: "100%",
                                boxShadow: "none",
                              },
                              "& .MuiOutlinedInput-input": {
                                textTransform: "uppercase",
                              },
                              width: "100%",
                            }}
                          />
                          {couponCode ? (
                            <CancelIcon
                              sx={{ ...resetDiscountIconStyle, top: "12px" }}
                              fontSize="small"
                              onClick={resetDiscountCode}
                            />
                          ) : null}
                        </Box>
                        <Typography
                          variant="h6"
                          sx={{
                            fontSize: "13px",
                            fontWeight: 600,
                            textDecoration: "underline",
                            cursor:
                              isFullDiscount || !couponCode
                                ? "inherit"
                                : "pointer",
                            textAlign: "center",
                            color:
                              isFullDiscount || !couponCode
                                ? theme?.palette?.grey["300"]
                                : theme?.palette?.dark?.darkTextSubHeader,
                            ml: 1.5,
                            mt: 1.5,
                          }}
                          onClick={() => {
                            let isValid = !/\s/.test(couponCode);
                            if (couponCode && isValid && !isFullDiscount) {
                              applyCouponCode(
                                selectedPackages,
                                isAppliedSKBucks,
                                false,
                                SKBucks,
                              );
                            }
                          }}
                        >
                          Apply
                        </Typography>
                        {Boolean(
                          formik.touched.discountCode &&
                            formik.errors.discountCode,
                        ) && (
                          <FormHelperText error>
                            {formik.errors.discountCode}
                          </FormHelperText>
                        )}
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Box display={"flex"} alignItems={"center"}>
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        flexWrap={"wrap"}
                      >
                        <Typography
                          variant="p"
                          sx={{
                            color: theme?.palette?.dark?.darkTextSubHeader,
                            fontWeight: 500,
                            fontFamily: "Poppins",
                            mr: 1,
                            mb: 0.5,
                          }}
                        >
                          Sketchplay Bucks -{" "}
                          <strong>({formatCurrency(SKBucksBalance)})</strong>
                        </Typography>
                        {SKBucksBalance === 0 ? null : (
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            position={"relative"}
                            width={1}
                          >
                            <NumericFormat
                              name="cost"
                              placeholder="Enter amount..."
                              onChange={(e) => {
                                setSKBucks(e?.target?.value);
                              }}
                              value={SKBucks}
                              style={numericInputStyle}
                              thousandSeparator={true}
                              prefix={"$"}
                              decimalScale={2}
                              allowNegative={false}
                            />
                            {SKBucksFormatted && SKBucksFormatted !== 0 ? (
                              <CancelIcon
                                sx={resetDiscountIconStyle}
                                fontSize="small"
                                onClick={resetSKBucksDiscount}
                              />
                            ) : null}
                          </Box>
                        )}
                      </Box>
                      {SKBucksBalance === 0 ? null : (
                        <Typography
                          variant="h6"
                          sx={{
                            fontSize: "13px",
                            fontWeight: 600,
                            textDecoration: "underline",
                            cursor:
                              !isFullDiscount &&
                              SKBucksFormatted > 0 &&
                              SKBucksBalance >= SKBucksFormatted &&
                              totalAmountSKBucks >= SKBucksFormatted
                                ? "pointer"
                                : "inherit",
                            textAlign: "center",
                            color:
                              !isFullDiscount &&
                              SKBucksFormatted > 0 &&
                              SKBucksBalance >= SKBucksFormatted &&
                              totalAmountSKBucks >= SKBucksFormatted
                                ? theme?.palette?.dark?.darkTextSubHeader
                                : theme?.palette?.grey["300"],
                            ml: 1.5,
                            mt: 2.5,
                          }}
                          onClick={() => {
                            setIsAppliedSKBucks(true);
                            if (
                              SKBucksFormatted > 0 &&
                              SKBucksBalance &&
                              SKBucksBalance >= SKBucksFormatted &&
                              totalAmountSKBucks >= SKBucksFormatted &&
                              !isFullDiscount
                            ) {
                              applyCouponCode(
                                selectedPackages,
                                true,
                                false,
                                SKBucks,
                              );
                            }
                          }}
                        >
                          Apply
                        </Typography>
                      )}
                    </Box>
                    {SKBucksBalance === 0 ? (
                      <Typography
                        variant="p"
                        sx={{
                          color: "red",
                          fontWeight: 500,
                          fontFamily: "Poppins",
                          mt: 1,
                        }}
                      >
                        Sketchplay Bucks Balance is 0 and can not be use to make
                        the payment
                      </Typography>
                    ) : null}
                  </Grid>
                </Grid>
              </DialogContent>
            </>
          )}
          {paymentStep === 2 && (
            <>
              {formik.values.paymentMethod === "Other" ? (
                <Grid item xs={12} mt={1}>
                  <CustomInputLabel htmlFor="athlete-school">
                    Description
                  </CustomInputLabel>
                  <CustomTextField
                    name="description"
                    placeholder="Description about payment method, etc..."
                    variant="outlined"
                    size="small"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.description}
                    required={true}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        border: `1px solid ${theme?.palette?.grey?.[400]}`,
                        width: "100%",
                        boxShadow: "none",
                      },
                      width: "100%",
                    }}
                    multiline
                    rows={6}
                  />
                  {Boolean(
                    formik.touched.description && formik.errors.description,
                  ) && (
                    <FormHelperText error>
                      {formik.errors.description}
                    </FormHelperText>
                  )}
                </Grid>
              ) : null}

              {!isFullDiscount && paymentStep === 2
                ? formik.values.paymentMethod === "Stripe" && (
                    <Box px={3}>
                      <StripePayment
                        handleClose={handleCancelPayment}
                        afterPayemntMethod={completeStripePaymentProcess}
                        formData={paymentFormData}
                        type="team"
                        payAmount={
                          SKBucksFormatted ? totalAmountSKBucks : totalAmount
                        }
                      />
                      {isShowStripeCardDetailsFilledError && (
                        <FormHelperText sx={{ ml: 2 }} error>
                          Fill out all the card details to pay (including - Card
                          Number, Expiry Date, and CVC)
                        </FormHelperText>
                      )}
                    </Box>
                  )
                : null}
            </>
          )}
          <DialogActions
            sx={{
              marginX: 6,
              paddingX: 0,
              pt: 2,
              pb: 3.5,
            }}
          >
            {formik.values.paymentMethod === "Other" || isFullDiscount ? (
              <Box width={1} sx={{ display: "flex", justifyContent: "end" }}>
                {/* <Box sx={{ width: '185px' }}>
                  {formik.values.paymentMethod === "Stripe" &&
                    <PoweredByStripe />
                  }
                </Box> */}
                <Box>
                  <Button
                    size="small"
                    variant="contained"
                    sx={{ paddingX: 3.5, paddingY: 1.2, mr: 2 }}
                    color="inherit"
                    onClick={() => {
                      setShowCancelConfirmPopup(true);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    type="submit"
                    sx={{ paddingX: 3.5, paddingY: 1.2 }}
                  >
                    Pay{" "}
                    {isFullDiscount
                      ? ""
                      : SKBucksFormatted
                      ? formatCurrency(totalAmountSKBucks)
                      : formatCurrency(totalAmount)}
                  </Button>
                </Box>
              </Box>
            ) : paymentStep === 1 ? (
              <Box display={"flex"} alignItems={"center"}>
                <Button
                  size="small"
                  variant="contained"
                  sx={{ paddingX: 3.5, paddingY: 1.2 }}
                  color="inherit"
                  onClick={() => {
                    setShowCancelConfirmPopup(true);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  sx={{ paddingX: 3.5, paddingY: 1.2, ml: 2 }}
                  onClick={() => {
                    individualStripePaymentIntent(
                      selectedPackages,
                      isAppliedSKBucks,
                    );
                    if (!checkDiscountResponse?.isValidDiscountCode) {
                      formik.setFieldValue("discountCode", "");
                    }
                    setPaymentStep(2);
                  }}
                  disabled={isTrueDiscountCode}
                >
                  Next
                </Button>
              </Box>
            ) : null}
          </DialogActions>
        </form>
      </Dialog>
      {showSuccessModal && (
        <SuccessPopup
          open={showSuccessModal}
          handleClose={handleSuccessModalClose}
          secondaryTitle="Payment Done Successfully!"
          isShowCloseBtn={false}
          isShowSubTitle={true}
          secondaryMessage="Tap anywhere out of this popup to close"
        />
      )}
      {showDeletePopup && (
        <DeleteConfirmPopup
          title={"Package"}
          message={"Are you sure you want to delete this Package?"}
          open={showDeletePopup}
          handleClose={() => {
            setShowDeletePopup(false);
            setPackRow({});
          }}
          handleConfirm={deleteCartItems}
        />
      )}
      {showCancelConfirmPopup && (
        <CancelConfirmDialog
          title={"Close Checkout"}
          message={"Are you sure want to close the checkout?"}
          open={showCancelConfirmPopup}
          handleClose={() => setShowCancelConfirmPopup(false)}
          handleCancel={() => {
            setShowCancelConfirmPopup(false);
          }}
          handleConfirmClose={handleConfirmClose}
          confirmBtnLabel={"Yes, Confirm"}
        />
      )}
      {showCancelConfirmPaymentPopup && (
        <CancelConfirmDialog
          title={"Cancel Payment"}
          message={"Are you sure want to cancel payment?"}
          open={showCancelConfirmPaymentPopup}
          handleClose={() => setShowCancelConfirmPaymentPopup(false)}
          handleCancel={() => {
            setShowCancelConfirmPaymentPopup(false);
          }}
          handleConfirmClose={handleConfirmCancelPayment}
          confirmBtnLabel={"Yes, Confirm"}
        />
      )}
    </>
  );
};

export default PackageCheckoutPayment;
